import { useEffect, useContext } from "react";
import { UserContext } from "../common/contexts/user-context";
import { CityContext } from "../common/contexts/city-context";
import AppRouter from "./routes/AppRouter";
import MyNavbar from "../navbar/components/_Navbar";
// import i18n from "../i18n";

function App() {
    const { logout, retrieveStoredToken } = useContext(UserContext);
    const cityContext = useContext(CityContext);

    const localUserData = retrieveStoredToken();

    // autologout
    let logoutTimer;
    useEffect(() => {
        if (localUserData) {
            logoutTimer = setTimeout(() => {
                logout();
            }, localUserData.duration);
        }
        return () => {
            clearTimeout(logoutTimer);
        };
    }, [localUserData, logout]);

    return (
        <>
            <MyNavbar cityName={cityContext.cityData?.name} />
            <AppRouter />
        </>
    );
}

export default App;
