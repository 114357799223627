import React, { useEffect, useContext } from "react";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import useFetch from "../common/hooks/useFetch";
import Alert from "../common/components/_DismissibleAlert";
import Loader from "../common/components/_Loader";
import CityWidget from "./components/_SelectCityWidget";
import hasPermission from "../common/utils/acessControl";
import { UserContext } from "../common/contexts/user-context";

const CitiesPage = () => {
    const isComponentMounted = useRef(true);
    const { data, loading } = useFetch("/admin/cities", isComponentMounted, []);
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("success");
    const [searchText, setSearchText] = useState([]);
    const [cities, setCities] = useState([]);
    const { role } = useContext(UserContext);

    useEffect(() => {
        if (data) {
            setCities(data);
        }
    }, [data]);

    const searchHandler = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
        if (e.target.value) {
            setSearchText(e.target.value.toLowerCase());
        }
    };

    const cityList = cities
        .filter((city) => city.name.toLowerCase().includes(searchText))
        .sort((a, b) => {
            if (a.name < b.name) return -1;
            return 1;
        })
        .map((city) => (
            <div className="select-city-widget col-md-3" key={city._id}>
                <CityWidget
                    city={city}
                    cities={cities}
                    setCities={setCities}
                    setAlertMessage={setAlertMessage}
                    setAlertMessageType={setAlertMessageType}
                />
            </div>
        ));

    return loading ? (
        <Loader />
    ) : (
        <div>
            {alertMessage && (
                <Alert message={alertMessage} type={alertMessageType} />
            )}
            <br></br>
            <div className="container city-list-container">
                {/* barra de filtro */}
                <div className="row">
                    <form className="col-md-9">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="city-search-input form-control"
                                name="search"
                                onChange={searchHandler}
                                value={searchText}
                                placeholder="  Buscar cidade"
                                aria-label="Buscar cidade"
                                aria-describedby="basic-addon2"
                            />
                            <span
                                className="input-group-text"
                                id="search-icon-container"
                            >
                                <button className="btn btn-link" type="submit">
                                    <BsSearch id="btn-search-icon" />
                                </button>
                            </span>
                        </div>
                    </form>
                    {hasPermission(role, "editor") && (
                        <Link
                            className="col-md-2 input-group-text"
                            id="add-city-group"
                            to="/admin/city/add"
                        >
                            <span>
                                <AiOutlinePlusSquare className="add-city-icon" />
                            </span>
                            <span className="add-user-label">NOVA</span>
                        </Link>
                    )}
                    <Button
                        className="btn-warning col-md-1 cities-page-back-button"
                        type="button"
                        onClick={() => navigate(`/inicio`)}
                    >
                        Voltar
                    </Button>
                </div>
                <br></br>
                <div className="row cities-page-selectCity-container">
                    {cityList}
                </div>
            </div>
        </div>
    );
};

export default CitiesPage;
