import React, { useEffect } from "react";
import { useContext, useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { UserContext } from "../common/contexts/user-context"

import { AiTwotoneEdit } from "react-icons/ai"
import { HiTrash } from "react-icons/hi"
import Button from 'react-bootstrap/Button';
import { AiOutlinePlusSquare } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import useFetch from "../common/hooks/useFetch";
import Alert from '../common/components/_DismissibleAlert';
import DeleteConfirmation from './components/_DeleteConfirmation';
import Loader from '../common/components/_Loader';

const UsersPage = () => {
    const navigate = useNavigate();
    const isComponentMounted = useRef(true);
    const userContext = useContext(UserContext)
    const { data, loading } = useFetch("/admin/users", isComponentMounted, [])

    const [alertMessage, setAlertMessage] = useState("")
    const [searchText, setSearchText] = useState([]);
    const [users, setUsers] = useState([]);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [deleteUser, setDeleteUser] = useState({});

    useEffect(() => {
        if (data) {
            setUsers(data)
        }
    }, [data])

    const editButtonClick = (user) => {
        navigate(`/admin/user/${user._id}/edit`);
    }

    const deleteButtonClick = (user) => {
        setModalMessage(`Deseja deletar o usuário ${user.fullname}?`);
        setDeleteUser(user);
        setDisplayConfirmationModal(true);
    }

    const submitDelete = () => {
        setAlertMessage("");
        fetch(
            process.env.REACT_APP_API_ENDPOINT + `/admin/user/${deleteUser._id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userContext.token}`
                },
            }
        ).then((res) => {
            if (res.status === 200) {
                setDisplayConfirmationModal(false);
                setAlertMessage(`Usuário deletado.`);
                setUsers(users.filter(u => u._id !== deleteUser._id))
            } else {
                console.log(res);
            }
        })
    }

    const searchHandler = (e) => {
        setSearchText(e.target.value);
        if (e.target.value) {
            setSearchText(e.target.value.toLowerCase());
        }
    }

    const userList = users.filter(
        user => user.fullname.toLowerCase().includes(searchText) ||
            user.access[0].toLowerCase().includes(searchText)
    ).sort(
        (a, b) => {
            if (a.fullname < b.fullname) return -1
            return 1
        }
    ).map(user => {
        return (
            <div className="row md-3" key={user._id}>
                <div className="user col-md-3">
                    {user.fullname}
                </div>
                <div className="user col-md-1">
                    {user.role}
                </div>
                <div className="user col-md-2">
                    {user.access[0] === 'all' ? 'São Paulo' : user.access[0]}
                </div>
                <div className="user col-md-4">
                    {user.username}
                </div>
                <div className="btn-row col-md-2">
                    <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                        <Button className="btn-primary btn-user btn-user-edit" type="button" onClick={() => editButtonClick(user)}>
                            <AiTwotoneEdit className="btn-user-edit-icon" />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Remover</Tooltip>}>
                        <Button className="btn-danger btn-user btn-user-delete" type="button" onClick={() => deleteButtonClick(user)}>
                            <HiTrash className="btn-user-delete-icon" />
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
        )
    })

    return loading ? <Loader /> : (
        <div >
            {alertMessage && <Alert message={alertMessage} type='success' />}
            <br></br>
            <div className="container user-list-container">
                {/* barra de filtro */}
                <div className="row">
                    <form className="col-md-9" >
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="users-search-input form-control"
                                name="search"
                                onChange={searchHandler}
                                value={searchText}
                                placeholder="Buscar usuário"
                                aria-label="Buscar usuário"
                                aria-describedby="basic-addon2"
                            />
                            <span className="input-group-text" id="search-icon-container">
                                <button className="btn btn-link" type="submit">
                                    <BsSearch id="btn-search-icon" />
                                </button>
                            </span>
                        </div>
                    </form>
                    <Link className="col-md-2 input-group-text" id="add-user-group" to="/admin/user/add">
                        <span>
                            <AiOutlinePlusSquare className="add-user-icon" />
                        </span>
                        <span className="add-user-label">NOVO</span>
                    </Link>
                    <Button
                        className="btn-warning col-md-1 users-page-back-button"
                        type="button"
                        onClick={() => navigate(`/inicio`)}
                    >
                        Voltar
                    </Button>
                </div>
                <br></br>
                {/* tabela de usuários */}
                <div className="row md-3">
                    <div className="users-header col-md-3">
                        Nome
                    </div>
                    <div className="users-header col-md-1">
                        Cargo
                    </div>
                    <div className="users-header col-md-2">
                        Cidade
                    </div>
                    <div className="users-header col-md-4">
                        Email
                    </div>
                </div>
                <hr className="edited"></hr>
                <div className="users-table-content">
                    {userList}
                </div>
            </div>
            <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={() => setDisplayConfirmationModal(false)}
                message={modalMessage}
            />
        </div>
    )
}

export default UsersPage