import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const IguToolbarB1 = (props) => {
    const icon = props.icon;
    const onClick = props.onClick;
    const btnDescription = props.btnDescription;

    const renderTooltip = (props) => (
        <Tooltip>
            {props}
        </Tooltip>
    )

    return (
        <OverlayTrigger 
            placement='top'
            overlay={renderTooltip(btnDescription)}
        >
            <Button
                variant="secondary"
                onClick={onClick}
                className="igu-toolbar-btn-tipo1"
                // size="lg"
            >
                {icon}
            </Button>
        </OverlayTrigger>
    )
}

export default IguToolbarB1;