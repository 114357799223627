import Alert from 'react-bootstrap/Alert';
import React, { useState } from "react";

function DismissibleAlert(props) {
    const [show, setShow] = useState(true);

    return (
        <Alert className={`alert-${props.type}`} variant={props.type} onClose={() => setShow(false)} show={show} dismissible>
            <p>
                {props.message}
            </p>
        </Alert>
    );

}

export default DismissibleAlert;