const Checkbox = ({ label, value, onChange }) => {
    return (
        <div className="my-checkbox-container">
            <input 
                type="checkbox" 
                checked={value} 
                onChange={onChange} 
                className='my-checkbox-check'
            />
            {label}
        </div>
    );
};

export default Checkbox;