import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

const IguMarkerPopup = (props) => {
    const cityID = props.cityID;
    const popupData = props.data;
    const { t } = useTranslation("iguMarkerPopup");

    return popupData && popupData.numInterseccao ? (
        <Card style={{ border: `1px solid ${popupData.popupColor}` }}>
            <Card.Header
                className="igu-marker-popup-card-header"
                style={{ backgroundColor: `${popupData.popupColor}` }}
            >
                <ul
                    className="nav nav-tabs card-header-tabs"
                    id="myTab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link tabText active"
                            id="int-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#int"
                            aria-selected="true"
                            role="tab"
                            href="#interseccao"
                        >
                            {t("int")}
                        </a>
                    </li>
                    {popupData.layerType === "interseccaoSemMap" && (
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link tabText"
                                id="cont-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#cont"
                                aria-selected="false"
                                role="tab"
                                href="#controlador"
                            >
                                {t("controller")}
                            </a>
                        </li>
                    )}
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link tabText"
                            id="doc-tab"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                                popupData.layerType
                                    ? `/docs/${popupData.layerType}/${popupData.numInterseccao}/${cityID}`
                                    : `/docs/${popupData.layerType}/${popupData.layerName}/${cityID}`
                            }
                        >
                            {t("docs")}
                        </a>
                    </li>
                </ul>
            </Card.Header>
            <Card.Body>
                <Card.Title className="igu-marker-popup-card-title">
                    <img
                        src={popupData.popupIconUrl}
                        width={20}
                        height={20}
                        alt="int-icon"
                    />
                    {popupData.numInterseccao}
                </Card.Title>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="int"
                        role="tabpanel"
                        aria-labelledby="int-tab"
                    >
                        <table className="table igu-marker-popup-table">
                            <tbody>
                                <hr className="igu-marker-popup-line"></hr>
                                {popupData.layerType === "pontosOnibus" && (
                                    <tr>
                                        <th scope="row">ID</th>
                                        <td>{popupData.id}</td>
                                    </tr>
                                )}
                                <tr>
                                    <th scope="row">{t("address")}</th>
                                    <td>{popupData.logradouro}</td>
                                </tr>
                                {popupData.layerType ===
                                    "interseccaoSemMap" && (
                                    <tr>
                                        <th scope="row">{t("dist_zone")}</th>
                                        <td>{popupData.bairroZona}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {popupData.layerType && (
                        <div
                            className="tab-pane fade"
                            id="cont"
                            role="tabpanel"
                            aria-labelledby="cont-tab"
                        >
                            <table className="table igu-marker-popup-table">
                                <tbody>
                                    <hr className="igu-marker-popup-line"></hr>
                                    <tr>
                                        <th scope="row">{t("net")}</th>
                                        <td>{popupData.rede}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("interlig")}</th>
                                        <td>
                                            <a
                                                href={`/docs/projeto/${popupData.interligacao}/${cityID}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {popupData.interligacao}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("model")}</th>
                                        <td>{popupData.modelo}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">ID</th>
                                        <td>{popupData.id}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("ring")}</th>
                                        <td>{popupData.anel}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("group")}</th>
                                        <td>{popupData.grupo}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{t("inst_control")}</th>
                                        <td>{popupData.instalacaoControle}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    ) : popupData && popupData.layerName ? (
        <Card style={{ border: `1px solid ${popupData.popupColor}` }}>
            <Card.Header
                className="igu-marker-popup-card-header"
                style={{ backgroundColor: `${popupData.popupColor}` }}
            >
                <ul
                    className="nav nav-tabs card-header-tabs"
                    id="myTab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link tabText active"
                            data-bs-toggle="tab"
                            data-bs-target="#proj"
                            aria-controls="proj"
                            aria-selected="true"
                            href="#projeto"
                            role="tab"
                        >
                            {popupData.layerType}
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            className="nav-link tabText"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={
                                popupData.layerType.includes("Projeto")
                                    ? `/docs/projeto/${popupData.layerName}/${cityID}`
                                    : popupData.layerType.includes(
                                          "Coordenação"
                                      )
                                    ? `/docs/coordenacao/${popupData.layerName}/${cityID}`
                                    : popupData.layerType.includes("Ônibus")
                                    ? `/docs/transporteColetivo/${popupData.layerName}/${cityID}`
                                    : popupData.layerType.includes("Simulação")
                                    ? `/docs/simulacao/${popupData.layerName}/${cityID}`
                                    : popupData.layerType.includes("Topografia")
                                    ? `/docs/topografia/${popupData.layerName}/${cityID}`
                                    : `/docs/desempenho/${popupData.layerName}/${cityID}`
                            }
                        >
                            {t("docs")}
                        </a>
                    </li>
                </ul>
            </Card.Header>
            <Card.Body>
                <Card.Title className="igu-marker-popup-card-title">
                    <img
                        src={popupData.popupIconUrl}
                        width={20}
                        height={20}
                        alt="proj-icon"
                    />
                    {popupData.layerName}
                </Card.Title>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="proj"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        {popupData.layerDesc && (
                            <div className="linha-bus-desc">
                                {popupData.layerDesc}
                            </div>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    ) : (
        <></>
    );
};

export default IguMarkerPopup;
