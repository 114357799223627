import { useContext } from 'react'
import { useQuery } from 'react-query';
import { UserContext } from "../contexts/user-context"

const useFetch = (url) => {
    const { token } = useContext(UserContext)
    const resource = process.env.REACT_APP_API_ENDPOINT + url;
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    };

    const getData = async () => await (await fetch(resource, options)).json();
    const { data, isLoading, error } = useQuery(url, getData);
    return { data, loading: isLoading, error };
};

export default useFetch;