import React from "react";

import { BsLink45Deg } from 'react-icons/bs';
import { AiFillCodepenCircle } from 'react-icons/ai';

const IguInterseccaoIcon = () => {
    return (
        <span className='interseccao-icone-exemplo'>
            {/* <div>077</div> */}
            <AiFillCodepenCircle/>/<BsLink45Deg/>
        </span>
    )
}

export default IguInterseccaoIcon;