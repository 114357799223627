import L from 'leaflet';

const iconBuilding = new L.icon({
    iconUrl: '/img/building-icon.svg',
    iconRetinaUrl: '/img/building-icon.svg',
    iconAnchor: [10,0],
    popupAnchor: [0,0],
    shadowUrl: [0,0],
    shadowSize: [0,0],
    shadowAnchor: [0,0],
    iconSize: [20,20],
    className: 'building-marker-icon'
});

export { iconBuilding };