import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import LanguageSwitch from "./_LanguageSwitch";

import { UserContext } from "../../common/contexts/user-context";
import UserProfileWidget from "./_NavUserWidget";

const MyNavbar = (props) => {
    const { isLoggedIn } = useContext(UserContext);
    const cityName = props.cityName;
    // const userCityLoading = props.loading;

    const myNavBrand = (
        <Navbar.Brand>
            <Nav.Link as={Link} to="/inicio">
                <img
                    width="50"
                    height="50"
                    src="/img/iguIconeIGU.svg"
                    className="logo-navbar"
                    alt="logo-igu"
                />
            </Nav.Link>
        </Navbar.Brand>
    );

    if (!isLoggedIn) {
        return <Navbar expand="md" className="nav myNav-loginPage" />;
    } else {
        return (
            <Navbar expand="md" className="nav myNav">
                <Container className="container-fluid">
                    {myNavBrand}

                    <Navbar>
                        <UserProfileWidget
                            cityName={cityName}
                            className="ms-auto "
                        />
                        <LanguageSwitch />
                    </Navbar>
                </Container>
            </Navbar>
        );
    }
};

export default MyNavbar;
