const toggleLayer = (panes, pane, setState) => {
    if (panes[pane]) {
        if (panes[pane].style.display !== 'none') {
            panes[pane].style.display = 'none'
            setState(false);
        } else {
            panes[pane].style.display = ''
            setState(true);
        }
    }    
}

export { toggleLayer };