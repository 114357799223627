import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import {
    BsFillCaretLeftFill, BsFillGeoFill, BsFillGeoAltFill,
    BsCalendarDate, BsClock, BsFillPeopleFill
} from 'react-icons/bs';
import { BiNetworkChart } from 'react-icons/bi';
import { FaWallet } from 'react-icons/fa'
import { UserContext } from "../../common/contexts/user-context";
import { CityContext } from "../../common/contexts/city-context";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import useFetch from "../../common/hooks/useFetch";
import CanvasFlowmapLayer from "../utils/CanvasFlowmapLayer";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const ODControls = (props) => {
    const myMap = props.map;
    const zoneamentoLayer = props.zoneamento;
    const { cityData } = useContext(CityContext);
    const { token } = useContext(UserContext);
    const cityName = cityData.name;
    const cityID = cityData._id;
    const [showOffcanvas, setShowOffcanvas] = useState(true);
    const initDiaTipo = [{ diaTipo: "DU", isSelected: true }, { diaTipo: "FDS", isSelected: true }];
    const initHoraPico = [{ horapico: "HP", isSelected: true }, { horapico: "EP", isSelected: true }];
    const defaultIntervalo = ["00:00", "23:59"];

    const [ODList, setODList] = useState([]);
    const [tipoPagamentos, setTipoPagamentos] = useState([]);
    const [initOrigState, setInitOrigState] = useState([]);
    const [initDestState, setInitDestState] = useState([]);
    const [initPagamentosState, setInitPagamentosState] = useState([]);
    const [origState, setOrigState] = useState([]);
    const [destState, setDestState] = useState([]);
    const [pagamentosState, setPagamentosState] = useState([]);
    const [odData, setOdData] = useState([]);
    const [datesState, setDatesState] = useState([]);
    const [shownInitDate, setShownInitDate] = useState("2001-01-01");
    const [shownEndDate, setShownEndDate] = useState("2001-01-01");
    const [diaTipoState, setDiaTipoState] = useState(initDiaTipo);
    const [horaPicoState, setHoraPicoState] = useState(initHoraPico);
    const [intervalo, setIntervalo] = useState(defaultIntervalo);
    const [shownInitTime, setShownInitTime] = useState(defaultIntervalo[0]);
    const [shownEndTime, setShownEndTime] = useState(defaultIntervalo[1]);
    const [picoDU, setPicoDU] = useState(['', '']);
    const [picoFDS, setPicoFDS] = useState(['', ''])
    const [tiposOD, setTiposOD] = useState([]);
    const fluxos = useRef();
    const [odSelecionada, setOdSelecionada] = useState("Selecione")

    const { data: matrizODOrigDest } = useFetch(`/od/origensedestinos/${cityData.name}`)
    const { data: matrizODPagamentos } = useFetch(`/od/tipospagamentos/${cityData.name}`)
    const { data: periodoData } = useFetch(`/od/periodo/${cityName}`);
    const { data: picoDUValue } = useFetch(`/od/pico/DU/${cityID}`);
    const { data: picoFDSValue } = useFetch(`/od/pico/FDS/${cityID}`);
    const { data: tiposODData } = useFetch(`/od/tiposOD/${cityName}`)
    const [faixaPass, setFaixaPass] = useState(80);

    const cor1Fluxo = 'hsl(220,100%,20%)';  // maior faixa
    const cor2Fluxo = 'hsl(220,100%,30%)';
    const cor3Fluxo = 'hsl(220,100%,40%)';
    const cor4Fluxo = 'hsl(220,100%,50%)';
    const cor5Fluxo = 'hsl(220,100%,60%)';
    const cor6Fluxo = 'hsl(220,100%,70%)';
    const cor7Fluxo = 'hsl(220,100%,80%)';
    const cor8Fluxo = 'hsl(220,100%,90%)'; // menor faixa

    // get initial od data (origem, destinos)
    useEffect(() => {
        if (matrizODOrigDest) {
            const listaOrigemDestinos = matrizODOrigDest.map(e => e._id).sort((a, b) => a - b)
            const _initOrigState = listaOrigemDestinos.map(e => ({ origem: e, isSelected: false }));
            const _initDestState = listaOrigemDestinos.map(e => ({ destino: e, isSelected: false }));
            setInitOrigState(_initOrigState);
            setInitDestState(_initDestState);
            setODList(listaOrigemDestinos);
            setOrigState(_initOrigState);
            setDestState(_initDestState);
        };
    }, [matrizODOrigDest]);

    // get tipo de pagamento
    useEffect(() => {
        if (matrizODPagamentos) {
            const TiposPagamentos = matrizODPagamentos.map(e => e._id).sort((a, b) => a > b ? 1 : -1);
            setTipoPagamentos(TiposPagamentos);
            setInitPagamentosState(TiposPagamentos.map(e => ({ tipo: e, isSelected: true })));
            setPagamentosState(TiposPagamentos.map(e => ({ tipo: e, isSelected: true })));
        };
    }, [matrizODPagamentos]);

    // get tipos de od
    useEffect(() => {
        if (tiposODData) {
            setTiposOD(tiposODData);
        };
    }, [tiposODData]);

    //  sets passenger segmentation
    useEffect(() => {
        if (odData) {
            const passArr = odData.map(a => a.QUANTIDADE_PASSAGEIROS);
            const maxPass = Math.max(...passArr);
            const minPass = Math.min(...passArr);
            setFaixaPass(maxPass - minPass);
        };
    }, [odData]);

    // loading periodo from database
    useEffect(() => {
        if (periodoData) {
            setDatesState(periodoData.map(e => ({ data: e._id, isSelected: false })))
        };
        myMap.fitBounds(zoneamentoLayer.getBounds());
    }, [periodoData, myMap, zoneamentoLayer]);

    // loading shown periodo for the 1st time
    useEffect(() => {
        if (datesState[0] && shownInitDate === "2001-01-01") {
            setShownInitDate(datesState[0].data);
            setShownEndDate(datesState[datesState.length - 1].data);
        };
    }, [datesState, shownInitDate]);

    // loading picos from database
    useEffect(() => {
        if (picoDUValue) {
            setPicoDU(picoDUValue);
        }
    }, [picoDUValue]);
    useEffect(() => {
        if (picoFDSValue) {
            setPicoFDS(picoFDSValue);
        }
    }, [picoFDSValue]);

    // update dateState after date input change
    useEffect(() => {
        if ((shownInitDate || shownEndDate) && periodoData) {
            const tempDateState = periodoData.map(e => ({ data: e._id, isSelected: false }));
            tempDateState.forEach(e => e.isSelected = false);  // seta tudo como false
            tempDateState.forEach(function (e) {
                if (e.data >= shownInitDate && e.data <= shownEndDate) {
                    e.isSelected = true;
                }
            })
            setDatesState(tempDateState)
        }
    }, [shownInitDate, shownEndDate, periodoData]);

    // update timeState after time input change
    useEffect(() => {
        const tempTimeState = [shownInitTime, shownEndTime];
        if (
            (shownInitTime !== picoDU[0] && shownEndTime !== picoDU[1]) &&
            (shownInitTime !== picoFDS[0] && shownEndTime !== picoFDS[1])
        ) setIntervalo(tempTimeState)
    }, [shownInitTime, shownEndTime, picoDU, picoFDS]);

    // update odData after controls update
    useEffect(() => {
        const buscarDados = async () => {
            const origensSelecionadas =
                origState
                    .filter(e => e.isSelected === true)
                    .map(e => e.origem)
                    .sort((a, b) => a - b);
            const destinosSelecionados =
                destState
                    .filter(e => e.isSelected === true)
                    .map(e => e.destino)
                    .sort((a, b) => a - b);
            const pagamentosSelecionados =
                pagamentosState
                    .filter(e => e.isSelected === true)
                    .map(e => e.tipo)
                    .sort((a, b) => a - b);
            const datasSelecionadas =
                datesState
                    .filter(e => e.isSelected === true)
                    .map(e => e.data)
                    .sort((a, b) => a > b);
            const diasTiposSelecionados =
                diaTipoState
                    .filter(e => e.isSelected === true)
                    .map(e => e.diaTipo)
            const horaPicoSelecionada =
                horaPicoState
                    .filter(e => e.isSelected === true)
                    .map(e => e.horapico)
            const intervaloSelecionado = intervalo

            const queryString = encodeURI(
                `?origens=${origensSelecionadas.join()}&destinos=${destinosSelecionados.join()}&pagamentos=${pagamentosSelecionados.join()}&datas=${datasSelecionadas.join()}&dt=${diasTiposSelecionados.join()}&intervalo=${intervaloSelecionado.join()}&pico=${horaPicoSelecionada.join()}`
            )

            const od = await (await fetch(
                process.env.REACT_APP_API_ENDPOINT + `/od/odData/${cityName}/${odSelecionada}/${queryString}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            )).json();
            setOdData(od);
        };
        buscarDados();
    }, [cityName, token, origState, destState, pagamentosState, datesState, diaTipoState, horaPicoState, intervalo, odSelecionada]);

    // update od flow plot
    useEffect(() => {
        plotarFluxos();
        return () => {
            fluxos.current.remove();
        }
    }, [odData, plotarFluxos]);

    const handleCloseOffcanvas = () => setShowOffcanvas(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);

    const ODClickHandler = (e) => {
        e.target.classList.toggle("btn-zona-selected");
        if (e.target.classList.contains("btn-origens")) {
            let origens = [...origState];
            const selectedOrig = origens.filter(o => parseInt(o.origem) === parseInt(e.target.innerText))[0];
            selectedOrig.isSelected = !selectedOrig.isSelected;
            setOrigState(origens)

        } else {
            let destinos = [...destState];
            const selectedDest = destinos.filter(d => parseInt(d.destino) === parseInt(e.target.innerText))[0];
            selectedDest.isSelected = !selectedDest.isSelected;
            setDestState(destinos);
        };
    };
    const toggleAllOrigens = (select) => {
        if (select) {
            setOrigState(ODList.map(e => ({ origem: e, isSelected: true })));
            document.querySelectorAll(".btn-origens").forEach(btnOrigem => {
                if (!btnOrigem.classList.contains("btn-zona-selected")) {
                    btnOrigem.classList.add("btn-zona-selected")
                }
            })

        } else {
            setOrigState(initOrigState);
            document.querySelectorAll(".btn-origens").forEach(btnOrigem => {
                if (btnOrigem.classList.contains("btn-zona-selected")) {
                    btnOrigem.classList.remove("btn-zona-selected")
                }
            })
        };
    };
    const toggleAllDestinos = (select) => {
        if (select) {
            setDestState(ODList.map(e => ({ destino: e, isSelected: true })));
            document.querySelectorAll(".btn-destinos").forEach(btnDestino => {
                if (!btnDestino.classList.contains("btn-zona-selected")) {
                    btnDestino.classList.add("btn-zona-selected")
                }
            })

        } else {
            setDestState(initDestState);
            document.querySelectorAll(".btn-destinos").forEach(btnDestino => {
                if (btnDestino.classList.contains("btn-zona-selected")) {
                    btnDestino.classList.remove("btn-zona-selected")
                }
            })
        };
    };
    const pagamentosClickHandler = (e) => {
        e.target.classList.toggle("btn-tipo-selected");
        const pagamentos = [...pagamentosState];
        const selectedTipo = pagamentos.filter(p => p.tipo === e.target.innerText)[0];
        selectedTipo.isSelected = !selectedTipo.isSelected;
        setPagamentosState(pagamentos);
    };
    const toggleAllPagamentos = (select) => {
        if (select) {
            setPagamentosState(initPagamentosState);
            document.querySelectorAll(".btn-pagamentos").forEach(btnPag => {
                if (!btnPag.classList.contains("btn-tipo-selected")) {
                    btnPag.classList.add("btn-tipo-selected");
                };
            });
        } else {
            setPagamentosState(tipoPagamentos.map(e => ({ tipo: e, isSelected: false })));
            document.querySelectorAll(".btn-pagamentos").forEach(btnPag => {
                if (btnPag.classList.contains("btn-tipo-selected")) {
                    btnPag.classList.remove("btn-tipo-selected");
                };
            });
        };
    };
    const initDateOnChange = (e) => {
        if (e.target.value) {
            setShownInitDate(e.target.value)
        } else {
            // if user delete init data, set to first exiting data
            setShownInitDate(datesState[0].data)
        }
    };
    const endDateOnChange = (e) => {
        if (e.target.value) {
            setShownEndDate(e.target.value)
        } else {
            // if user delete end data, set to last exiting data
            setShownEndDate(datesState[datesState.length - 1].data)
        }
    };
    const diaTipoOnClick = (e) => {
        const tempDTState = [...diaTipoState];
        const labelDU = document.querySelectorAll(".btn-dia-tipo")[0];
        const labelFDS = document.querySelectorAll(".btn-dia-tipo")[1];
        const labelHP = document.querySelectorAll(".btn-hora-pico")[0];
        const labelEP = document.querySelectorAll(".btn-hora-pico")[1];
        if (e.target.innerText === "DU" && labelFDS.classList.contains("btn-zona-selected")) {
            e.target.classList.toggle("btn-zona-selected");
            tempDTState[0].isSelected = !diaTipoState[0].isSelected;
        } else if (e.target.innerText === "FDS" && labelDU.classList.contains("btn-zona-selected")) {
            e.target.classList.toggle("btn-zona-selected");
            tempDTState[1].isSelected = !diaTipoState[1].isSelected;
        }
        // if both du and fds selected
        if (labelDU.classList.contains("btn-zona-selected") || labelFDS.classList.contains("btn-zona-selected")) {
            // deselect hp or ep, if selected
            if (!labelHP.classList.contains("btn-zona-selected")) labelHP.classList.add("btn-zona-selected")
            if (!labelEP.classList.contains("btn-zona-selected")) labelEP.classList.add("btn-zona-selected")
            setHoraPicoState(initHoraPico);
            setShownInitTime(defaultIntervalo[0]);
            setShownEndTime(defaultIntervalo[1]);
        }
        if (tempDTState) setDiaTipoState(tempDTState);
    };
    const initTimeOnChange = (e) => {
        if (e.target.value) {
            setShownInitTime(e.target.value);
        } else if (!e.target.value) {
            setShownInitTime(defaultIntervalo[0])
        }
    };
    const endTimeOnChange = (e) => {
        if (e.target.value) {
            setShownEndTime(e.target.value);
        } else if (!e.target.value) {
            setShownEndTime(defaultIntervalo[1])
        }
    };
    const horapicoOnClick = (e) => {
        const tempHPState = [...horaPicoState];
        const labelHP = document.querySelectorAll(".btn-hora-pico")[0];
        const labelEP = document.querySelectorAll(".btn-hora-pico")[1];
        const labelDU = document.querySelectorAll(".btn-dia-tipo")[0];
        const labelFDS = document.querySelectorAll(".btn-dia-tipo")[1];

        // make sure only one of the options is selected
        // if is HP
        if (e.target.innerText === "HP") {
            // if selecting HP now
            if (!labelHP.classList.contains("btn-zona-selected")) {
                // change HP state
                tempHPState[0].isSelected = true;
                // if ep already selected
                if (labelEP.classList.contains("btn-zona-selected")) {
                    setShownInitTime(defaultIntervalo[0]);
                    setShownEndTime(defaultIntervalo[1]);
                }
                e.target.classList.toggle("btn-zona-selected");
                //  if deselecting HP now
            } else {
                // only if ep is selected
                if (labelEP.classList.contains("btn-zona-selected")) {
                    tempHPState[0].isSelected = false;
                    // if both DU and FDS was selected
                    if (labelDU.classList.contains("btn-zona-selected") && labelFDS.classList.contains("btn-zona-selected")) {
                        // deselect FDS
                        labelFDS.click();
                        setShownInitTime(picoDU[0]);
                        setShownEndTime(picoDU[1]);
                    } else if (labelDU.classList.contains("btn-zona-selected")) {
                        setShownInitTime(picoDU[0]);
                        setShownEndTime(picoDU[1]);
                    } else if (labelFDS.classList.contains("btn-zona-selected")) {
                        setShownInitTime(picoFDS[0]);
                        setShownEndTime(picoFDS[1]);
                    }
                    e.target.classList.toggle("btn-zona-selected");
                }
            }

        } else if (e.target.innerText === "EP") {
            // if selecting EP now
            if (!labelEP.classList.contains("btn-zona-selected")) {
                // change EP state
                tempHPState[1].isSelected = true;
                // if hp already selected
                if (labelHP.classList.contains("btn-zona-selected")) {
                    setShownInitTime(defaultIntervalo[0]);
                    setShownEndTime(defaultIntervalo[1]);
                }
                e.target.classList.toggle("btn-zona-selected");
                //  if deselecting EP now
            } else if (labelHP.classList.contains("btn-zona-selected")) {
                tempHPState[1].isSelected = false;
                // if both DU and FDS was selected
                if (labelDU.classList.contains("btn-zona-selected") && labelFDS.classList.contains("btn-zona-selected")) {
                    // deselect FDS
                    labelFDS.click();
                    setShownInitTime(picoDU[0]);
                    setShownEndTime(picoDU[1]);
                } else if (labelDU.classList.contains("btn-zona-selected")) {
                    setShownInitTime(picoDU[0]);
                    setShownEndTime(picoDU[1]);
                } else if (labelFDS.classList.contains("btn-zona-selected")) {
                    setShownInitTime(picoFDS[0]);
                    setShownEndTime(picoFDS[1]);
                }
                e.target.classList.toggle("btn-zona-selected");
            }
        }
        setHoraPicoState(tempHPState);

    };
    const plotarFluxos = useCallback(() => {
        const faixasPassageirosDia = [
            {
                min: 0,
                max: Math.round(faixaPass * 0.15),
                cor: cor8Fluxo,
                largura: 6
            },
            {
                min: Math.round(faixaPass * 0.15),
                max: Math.round(faixaPass * 0.3),
                cor: cor7Fluxo,
                largura: 8
            },
            {
                min: Math.round(faixaPass * 0.3),
                max: Math.round(faixaPass * 0.45),
                cor: cor6Fluxo,
                largura: 10
            },
            {
                min: Math.round(faixaPass * 0.45),
                max: Math.round(faixaPass * 0.60),
                cor: cor5Fluxo,
                largura: 12
            },
            {
                min: Math.round(faixaPass * 0.6),
                max: Math.round(faixaPass * 0.75),
                cor: cor4Fluxo,
                largura: 14
            },
            {
                min: Math.round(faixaPass * 0.75),
                max: Math.round(faixaPass * 0.8),
                cor: cor3Fluxo,
                largura: 14
            },
            {
                min: Math.round(faixaPass * 0.8),
                max: Math.round(faixaPass * 0.9),
                cor: cor2Fluxo,
                largura: 14
            },
            {
                min: Math.round(faixaPass * 0.9),
                max: Infinity,
                cor: cor1Fluxo,
                largura: 14
            }
        ];
        function getCoordZona(numZona) {
            var coord;
            zoneamentoLayer.eachLayer(function (layer) {
                if (parseInt(layer.feature.properties.Zona) === parseInt(numZona)) {
                    coord = [layer.getBounds().getCenter()['lng'], layer.getBounds().getCenter()['lat']];
                };
            })
            return coord
        };
        // objeto json para plotar no mapa
        const flowGeoJson = {
            "type": "FeatureCollection",
            "name": "Fluxos_Censitário_Atibaia",
            "crs": {
                "type": "name",
                "properties": {
                    "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                }
            },
            "features": odData.map(
                e => {
                    var origem = getCoordZona(e._id.ORIGEM);
                    var destino = getCoordZona(e._id.DESTINO);

                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": origem
                        },
                        "properties": {
                            "origin_id": e._id.ORIGEM,
                            "origin_lon": origem[0],
                            "origin_lat": origem[1],
                            "destination_id": e._id.DESTINO,
                            "destination_lon": destino[0],
                            "destination_lat": destino[1],
                            "passageiros": Math.round(e.QUANTIDADE_PASSAGEIROS)
                        }
                    }
                }
            ),
        };
        if (fluxos.current) { fluxos.current.remove(); };
        fluxos.current = new CanvasFlowmapLayer(flowGeoJson, {
            pane: 'shadowPane',
            canvasBezierStyle: {
                type: 'classBreaks',
                field: 'passageiros',
                classBreakInfos: faixasPassageirosDia.map(e =>
                ({
                    classMinValue: e.min,
                    classMaxValue: e.max,
                    symbol: {
                        strokeStyle: e.cor,
                        lineWidth: e.largura,
                        lineCap: 'round',
                        shadowColor: e.cor,
                        shadowBlur: 3.5
                    }
                })
                ),
                // the layer will use the defaultSymbol if a data value doesn't fit
                // in any of the defined classBreaks
                defaultSymbol: {
                    strokeStyle: 'red',
                    lineWidth: 10,
                    lineCap: 'round',
                    shadowColor: 'red',
                    shadowBlur: 1.5
                },
            },
            animatedCanvasBezierStyle: {
                type: 'simple',
                symbol: {
                    // use canvas styling options (compare to CircleMarker styling below)
                    strokeStyle: 'rgb(255, 255, 255)',
                    lineWidth: 1.25,
                    lineDashOffsetSize: 4, // custom property used with animation sprite sizes
                    lineCap: 'round',
                    shadowColor: 'rgb(255, 255, 255)',
                    shadowBlur: 2
                }
            },
            pathDisplayMode: 'all',
            animationStarted: true,
            animationEasingFamily: 'Quadratic',
            animationEasingType: 'In',
            animationDuration: 1500,

        })
        fluxos.current.addTo(myMap);
    },[faixaPass, myMap, odData, zoneamentoLayer]);

    const listFluxos = odData
        .sort((a, b) => b.QUANTIDADE_PASSAGEIROS - a.QUANTIDADE_PASSAGEIROS)
        .map(
                (e, i) => {
                    var origem = parseInt(e._id.ORIGEM).toLocaleString('en-US',
                        {
                            minimumIntegerDigits: 3,
                            useGrouping: false
                        }
                    );
                    var destino = parseInt(e._id.DESTINO).toLocaleString('en-US',
                        {
                            minimumIntegerDigits: 3,
                            useGrouping: false
                        }
                    );

                    return (
                        <tr key={i}>
                            <td>{origem}</td>
                            <td>{destino}</td>
                            <td>{Math.round(e.QUANTIDADE_PASSAGEIROS)}</td>
                        </tr>
                    )
                }
        )

    const odSelect = (e) => {
        setOdSelecionada(e.target.text);
    };

    const tiposODDropdown = 
        <DropdownButton 
            variant="Primary" title={odSelecionada} className="od-tipo-dropdown"
        >
            {tiposOD.map((tipo, id) => (
                <Dropdown.Item
                    key={id} 
                    onClick={e => odSelect(e)}
                >{tipo}</Dropdown.Item>
            ))}
        </DropdownButton>

    return (
        <>
            <div className='leaflet-control-container'>
                <div className='leaflet-top leaflet-right'>
                    <div className='leaflet-control od-controls'>
                        {!showOffcanvas && <Button variant="secundary" onClick={handleShowOffcanvas} className="od-controls-expand-button">
                            <BsFillCaretLeftFill />
                            Controles - Matriz OD
                        </Button>}
                    </div>
                </div>
            </div>
            <Offcanvas
                show={showOffcanvas}
                onHide={handleCloseOffcanvas}
                placement="end"
                backdrop={false}
                id="od-controles-offcanvas"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="od-controles-offcanvas-title">
                        <BiNetworkChart />
                        <div className="od-controles-title-text">Matriz OD  - </div>{tiposODDropdown}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {odSelecionada !== "Selecione" && <Offcanvas.Body>
                    {/* <!-- selecao de periodo --> */}
                    <div className="input-group">
                        <span className="input-group-text"><BsCalendarDate size={20} /></span>
                        <input
                            value={shownInitDate}
                            onChange={e => initDateOnChange(e)}
                            type="date"
                            className="form-control data-periodo"
                            id="data-inicio"
                            aria-label="Data Início"
                        ></input>
                        <input
                            value={shownEndDate}
                            onChange={e => endDateOnChange(e)}
                            type="date"
                            className="form-control data-periodo"
                            id="data-fim"
                            aria-label="Data Fim"
                        ></input>
                        {/* <!-- selecao dia tipo --> */}
                        <label
                            onClick={e => diaTipoOnClick(e)}
                            className="btn btn-outline-primary btn-dia-tipo btn-zona-selected btn-filtro"
                            title="Dia útil">
                            DU</label>
                        <label
                            onClick={e => diaTipoOnClick(e)}
                            className="btn btn-outline-primary btn-dia-tipo btn-zona-selected btn-filtro"
                            title="Fim de semana">
                            FDS</label>
                    </div>
                    {/* <!-- selecao da hora --> */}
                    <div className="input-group">
                        <span className="input-group-text"><BsClock size={20} /></span>
                        <input
                            value={shownInitTime}
                            onChange={e => initTimeOnChange(e)}
                            type="time"
                            className="form-control hora-periodo"
                            id="hora-inicio"
                            aria-label="Hora Início"
                        ></input>
                        <input
                            value={shownEndTime}
                            onChange={e => endTimeOnChange(e)}
                            type="time"
                            className="form-control hora-periodo"
                            id="hora-fim" aria-label="Hora Fim"
                        ></input>
                        {/* <!-- selecao hora pico --> */}
                        <label
                            onClick={e => horapicoOnClick(e)}
                            className="btn btn-zona-selected btn-outline-primary btn-hora-pico btn-filtro"
                            title="Hora pico">
                            HP</label>
                        <label
                            onClick={e => horapicoOnClick(e)}
                            className="btn btn-zona-selected btn-outline-primary btn-hora-pico btn-filtro"
                            title="Entre pico">
                            EP</label>
                    </div>

                    <div className="accordion accordion-flush">
                        {/* Origens */}
                        <div className="accordion-item od-controles-acc-item">
                            <h2 className="accordion-header od-controles-acc-header" id="flush-headingOne">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="true"
                                    aria-controls="flush-collapseOne"
                                >
                                    <BsFillGeoFill />
                                    ORIGENS </button>
                            </h2>
                            <Button
                                variant="primary"
                                onClick={() => toggleAllOrigens(true)}
                                className="od-controls-acc-select-all-button"
                            >
                                Todas
                            </Button>
                            <Button
                                variant="secundary"
                                onClick={() => toggleAllOrigens(false)}
                                className="od-controls-acc-limpar-all-button"
                            >
                                Limpar
                            </Button>
                            <div id="flush-collapseOne" className="accordion-collapse show" aria-labelledby="flush-headingOne">
                                <div className="accordion-body">
                                    <div className="row">
                                        {ODList.map((origem, i) => (
                                            <div className="col-md-2 botao-zonas-container" key={i}>
                                                <span
                                                    className="btn-zonas btn-origens btn-filtro"
                                                    type="button"
                                                    onClick={e => ODClickHandler(e)}>
                                                    {origem.toLocaleString('en-US', {
                                                        minimumIntegerDigits: 3,
                                                        useGrouping: false
                                                    })}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Destinos */}
                        <div className="accordion-item od-controles-acc-item">
                            <h2 className="accordion-header od-controles-acc-header" id="flush-headingTwo">
                                <button
                                    className="accordion-button "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="flush-collapseTwo">
                                    <BsFillGeoAltFill />
                                    DESTINOS</button>
                            </h2>
                            <Button
                                variant="primary"
                                onClick={() => toggleAllDestinos(true)}
                                className="od-controls-acc-select-all-button"
                            >
                                Todas
                            </Button>
                            <Button
                                variant="secundary"
                                onClick={() => toggleAllDestinos(false)}
                                className="od-controls-acc-limpar-all-button"
                            >
                                Limpar
                            </Button>
                            <div id="flush-collapseTwo" className="accordion-collapse show" aria-labelledby="flush-headingTwo" >
                                <div className="accordion-body">
                                    <div className="row">
                                        {ODList.map((destino, i) => (
                                            <div className="col-md-2 botao-zonas-container" key={i}>
                                                <span
                                                    className="btn-zonas btn-destinos btn-filtro"
                                                    type="button"
                                                    onClick={e => ODClickHandler(e)}
                                                >
                                                    {destino.toLocaleString('en-US', {
                                                        minimumIntegerDigits: 3,
                                                        useGrouping: false
                                                    })}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tipo de pagamento */}
                        {tipoPagamentos && <div className="accordion-item od-controles-acc-item">
                            <h2 className="accordion-header od-controles-acc-header" id="flush-headingThree">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    <FaWallet />
                                    PAGAMENTOS</button>
                            </h2>
                            <Button
                                variant="primary"
                                onClick={() => toggleAllPagamentos(true)}
                                className="od-controls-acc-select-all-button"
                            >
                                Todas
                            </Button>
                            <Button
                                variant="secundary"
                                onClick={() => toggleAllPagamentos(false)}
                                className="od-controls-acc-limpar-all-button"
                            >
                                Limpar
                            </Button>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" >
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {tipoPagamentos.map((pagamento, i) => (
                                                <span
                                                    className="btn-pagamentos btn-filtro btn-tipo-selected"
                                                    type="button"
                                                    key={i}
                                                    onClick={e => pagamentosClickHandler(e)}
                                                >
                                                    {pagamento}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* Sexo */}

                        {/* Idade */}

                        {/* tabela Quantidade de passageiros */}
                        <div className="accordion-item od-controles-acc-item">
                            <h2 className="accordion-header od-controles-acc-header" id="flush-headingFour">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour">
                                    <BsFillPeopleFill />
                                    PASSAGEIROS</button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" >
                                <div className="accordion-body">
                                    <div className="limitar-tabela">
                                        <table className="table table-hover fluxos-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Origem</th>
                                                    <th scope="col">Destino</th>
                                                    <th scope="col">Passageiros</th>
                                                </tr>
                                            </thead>
                                            <tbody className="linha-tb-fluxos">
                                                {listFluxos}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Offcanvas.Body>}
            </Offcanvas>
        </>

    )
};

export default ODControls;