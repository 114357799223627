import React, { useEffect } from "react";
import { useState, useRef } from "react"
import { BsSearch } from "react-icons/bs"
import Button from 'react-bootstrap/Button';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import useFetch from "../common/hooks/useFetch";
import { useNavigate } from "react-router-dom";

const LogsPage = () => {
    const isComponentMounted = useRef(true);
    const { data } = useFetch("/admin/logs", isComponentMounted, null)
    const [searchText, setSearchText] = useState([]);
    const [logs, setLogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setLogs(data)
        }

        return () => {
            isComponentMounted.current = false;
        }
    }, [data])

    const searchHandler = (e) => {
        setSearchText(e.target.value);
        if (e.target.value) {
            setSearchText(e.target.value.toLowerCase());
        }
    }


    const logList = logs.filter(
        log => log.name.toLowerCase().includes(searchText) ||
            log.city.toLowerCase().includes(searchText) ||
            log.date.toLowerCase().includes(searchText) ||
            log.time.toLowerCase().includes(searchText) ||
            log.method?.toLowerCase().includes(searchText) ||
            log.path?.toLowerCase().includes(searchText)
    ).sort(
        (a, b) =>
            (a.date < b.date) || (a.date === b.date && a.time <= b.time)
        // if ((a.date === b.date) || (a.date === b.date && a.time === b.time)) return 0          
        // return 1

    ).map(log => (
        <tr className="row" key={log._id}>
            <td className="col-md-3">
                {log.name}
            </td>
            <td className="col-md-2">
                {log.city}
            </td>
            <td className="col-md-1">
                {log.date}
            </td>
            <td className="col-md-1">
                {log.time}
            </td>
            <td className="col-md-1">
                {log.method}
            </td>
            <td className="col-md-4">
                {log.path}
            </td>
        </tr>
    ))

    return (
        <div className="container log-list-container">
            {/* barra de filtro */}
            <div className="row log-page-search-bar-container">
                <form className="log-search-bar" >
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="users-search-input form-control"
                            name="search"
                            onChange={searchHandler}
                            value={searchText}
                            placeholder="Buscar registro"
                            aria-label="Buscar registro"
                            aria-describedby="basic-addon2"
                        />
                        <span className="input-group-text" id="search-icon-container">
                            <button className="btn btn-link" type="submit">
                                <BsSearch id="btn-search-icon" />
                            </button>
                        </span>
                    </div>
                </form>
                <Button
                    className="btn-warning log-page-return-button"
                    type="button"
                    onClick={() => navigate(`/inicio`)}
                >
                    Voltar
                </Button>
            </div>
            {/* tabela de logs */}
            <table className="log-table col-md-12">
                <thead className="col-md-12">
                    <tr className="row log-table-header">
                        <th className="logs-header col-md-3">
                            Nome
                        </th>
                        <th className="logs-header col-md-2">
                            Cidade
                        </th>
                        <th className="logs-header col-md-1">
                            Dia
                        </th>
                        <th className="logs-header col-md-1">
                            Hora
                        </th>
                        <th className="logs-header col-md-1">
                            Método
                        </th>
                        <th className="logs-header col-md-4">
                            Diretório
                        </th>
                    </tr>
                </thead>
                <tbody className="log-table-content">
                    {logList}
                </tbody>
            </table>
        </div>
    )
}

export default LogsPage