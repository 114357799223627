import React from "react"

const Loader = (props) => {
    return (
        <div className="loader-div" >
            <img className="loader" {...props} src="/img/loader2.gif" alt="loader"/>
        </div>
    )
}

export default Loader