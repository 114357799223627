import { useContext } from 'react'
import { UserContext } from "../contexts/user-context"
import { useQuery } from 'react-query';

const useGetBrasao = (cityID) => {
    const { token } = useContext(UserContext)
    const resource = process.env.REACT_APP_API_ENDPOINT + `/igu/brasao/${cityID}`;
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    };

    const getData = () => 
        fetch(resource, options)
            .then(res => res.blob())
            .then(data => window.URL.createObjectURL(data));
    const { data, isLoading, error } = useQuery(cityID, getData);
    // console.log(data)
    return { data, loading: isLoading, error };
};

export default useGetBrasao;