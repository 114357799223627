import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const ModalInfoGeral = (props) => {
    const show = props.show;
    const handleClose = props.handleClose;
    const contrato = props.contrato;

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='info-modal-title'>Informações Gerais</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src="/img/iguIconeIGU.svg" height="56" width="auto" align="left" className='info-modal-icone-igu' alt='igu-ico'/>
                <h1 style={{fontSize:'5rem'}}>Interface Gráfica Urbana</h1>
                {/* <h4>Desenvolvido por <a href="http://www.tranzum.com.br/" target="Tranzum">Tranzum Planejamento e Consultoria de Trânsito Ltda</a>.</h4> */}
                <br/>
                <h2>
                    {contrato}
                </h2>
                 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} size='lg'>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default ModalInfoGeral;