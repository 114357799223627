export const containsSearchText = (text, searchText) => {
    if (text) {
        return String(text).toLowerCase().includes(searchText.toLowerCase());
    }
};
export const replaceSearchedText = (text, searchText) => {
    // if (text) {
    const searchTextLen = searchText.length;
    if (searchTextLen === 0) {
        return text;
    }
    let startIndex = 0;
    let index;
    const resArray = [];
    const tempText = text.toLowerCase();
    const tempSearchStr = searchText.toLowerCase();
    while ((index = tempText.indexOf(tempSearchStr, startIndex)) > -1) {
        resArray.push(text.substring(startIndex, index));
        resArray.push(<b>{text.substring(index, index + searchTextLen)}</b>);
        startIndex = index + searchTextLen;
    }
    resArray.push(text.substring(startIndex));

    return resArray;
    // }
};
