import React from 'react'

const NotFoundPage = () => {
    return (
        <div id="wrapper">
            <img src="https://i.imgur.com/qIufhof.png" alt='Not found'/>
            <div id="info">
                <h3>Página não encontrada</h3>
            </div>
        </div >
    )
}

export default NotFoundPage