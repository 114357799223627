import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from "../../common/contexts/user-context"

const PrivateRoute = ({ children }) => {

    const isAuthenticated = useContext(UserContext).isLoggedIn

    return (
        isAuthenticated ? children : <Navigate to={"/login" } />
    )
};

export default PrivateRoute