import React, { useContext, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BsPersonCircle } from "react-icons/bs";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { UserContext } from "../common/contexts/user-context";
import Alert from '../common/components/_DismissibleAlert';

export const LoginPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const {login} = useContext(UserContext)

    // console.log(process.env.REACT_APP_API_ENDPOINT)
    const formSubmitHandler = e => {
        e.preventDefault()
        setIsSubmitting(true)
        setError("")

        const genericErrorMessage = "Something went wrong! Please try again later."

        fetch(process.env.REACT_APP_API_ENDPOINT + "/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: email, password }),
        }).then(async response => {
            setIsSubmitting(false)
            if (!response.ok) {
                if (response.status === 400) {
                    setError("Favor preencher todos os campos corretamente.");
                } else if (response.status === 401) {
                    setError("Usuário e/ou senha inválidos.");
                } else {
                    setError(genericErrorMessage);
                    // console.log(response);
                    // console.log(process.env.REACT_APP_API_ENDPOINT)
                }
            } else {
                const data = await response.json();
                login(data.token, data.role, data.expiresIn, data.userCityID);

                // console.log(data.expiresIn)
            }
        }).catch(error => {
            setIsSubmitting(false);
            // console.log(error);
        })
    }

    return (
        <>
            {error && <Alert message={error} type="danger"> </Alert>}
            <div className="outter-div">
                <div className="flex-child left-box">
                    <div className="left-outter-box">
                        <img
                            width="350"
                            height="350"
                            src="/img/Logo IGU-loginpage.svg"
                            className="icone-igu-login-page"
                            alt="icone-igu"
                        ></img>
                        <div className="phrase-login-page">A cidade nas suas mãos. Aqui e agora.</div>
                    </div>
                </div>
                <div className="flex-child">
                    <div className="login-box">
                        <BsPersonCircle className="person-icon-login" />
                        <div className="welcome-text">Bem vindo(a)!</div>
                        <Form onSubmit={formSubmitHandler} className="login-form">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    className="form-input"
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control
                                    className="form-input"
                                    type="password"
                                    placeholder="Senha"
                                    name="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button className="login-button" variant="primary" type="submit">
                                {isSubmitting ? "Entrando..." : "Entrar"}
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="footer-text">
                    Interfáce Gráfica Urbana © 2022 | Todos os direitos reservados.
                </div>
            </footer>
        </>
    )
}

export default LoginPage