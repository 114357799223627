import L from "leaflet";
import React from "react";
import ReactDomServer from "react-dom/server";

import { getMarkerIcon } from "./getMarkerIcon";
import { getTooltipText } from "./getTooltipText";
import { getPopupData } from "./getPopupData";
import IguMarkerPopup from "../components/_IguMarkerPopup";

const createPointLayer = (map, pane, data, layerType, cityID, t) => {
    data.forEach((e) => {
        const eLat = e.geometry.coordinates[1];
        const eLon = e.geometry.coordinates[0];
        const icon = getMarkerIcon(e, layerType);
        const popupData = getPopupData(e, layerType);
        const popupContent = ReactDomServer.renderToString(
            <IguMarkerPopup data={popupData} cityID={cityID} />
        );
        const marker = new L.marker([eLat, eLon], {
            pane: pane,
            icon: icon,
        }).bindTooltip(getTooltipText(e, layerType, t), {
            sticky: true,
            direction: "right",
        });
        if (popupContent) {
            marker.bindPopup(popupContent, { maxWidth: 260, minWidth: 260 });
        }
        marker.addTo(map);
    });
};

export { createPointLayer };
