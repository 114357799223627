import React from "react";

const getPopupData = (feature, layerType) => {
    const legendIcons = [
        "/img/codepen-circle.svg",
        "/img/link-45deg.svg",
        "/img/camera-video-fill.svg",
        "/img/arrow-repeat.svg",
        "/img/exclamation-triangle.svg",
        "/img/bezier2.svg",
        "/img/intersect.svg",
        "/img/three-dots.svg",
        "/img/speedometer.svg",
        "/img/bus.svg",
        "/img/no-lights.svg",
        "/img/stoplights-fill.svg",
        "/img/arrow-repeat.svg",
        "/img/file-earmark-play.svg",
        "/img/triangle-half.svg",
        "/img/signpost-split-fill.svg",
        "/img/globe.svg",
    ];

    const ehProjUrb =
        feature.properties.IGUCAMADA === "ProjetosUrbanosImplantados";
    const ehRotasCoord = feature.properties.IGUCAMADA === "BandasDeCoordenacao";
    const ehRedeInter = feature.properties.IGUCAMADA === "RedeInterligacao";
    const ehRotasDes = feature.properties.IGUCAMADA === "RotasDesempenho";
    const ehAreaSim = feature.properties.IGUCAMADA === "AreasSimulacao";
    const ehTopografia = feature.properties.IGUCAMADA === "Topografia";

    // point layers
    if (feature.properties.OINTERSECAONUMERO) {
        const logradouro = [
            feature.properties.OENDLOCALP,
            feature.properties.OENDLOCALS,
            feature.properties.OENDLOCALT,
        ]
            .filter((e) => e !== "")
            .flatMap((e, i) => [
                <span key={2 * i}>{e}</span>,
                <br key={2 * i + 1} />,
            ])
            .slice(0, -1);
        const numInterseccao = feature.properties.OINTERSECAONUMERO;
        let popupIconUrl;
        let popupColor;

        if (
            feature.properties.OINTERSECAOSITUACAO &&
            numInterseccao !== "zyx" &&
            !feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                "escopo"
            )
        ) {
            if (layerType === "interseccaoSemMap") {
                const bairroZona = feature.properties.OREDE.includes("C")
                    ? "Central"
                    : feature.properties.OREDE.includes("N")
                    ? "Norte"
                    : feature.properties.OREDE.includes("S")
                    ? "Sul"
                    : feature.properties.OREDE.includes("E")
                    ? "Leste"
                    : feature.properties.OREDE.includes("W")
                    ? "Oeste"
                    : "";
                const rede = bairroZona + ` (${feature.properties.OSUBREDE})`;
                const interligacao = feature.properties.OINTERLIGACAO;
                const modelo = feature.properties.OCTRL;
                const id = feature.properties.OCTRLID;
                const anel = feature.properties.OANELNUMERO;
                const grupo = feature.properties.OGRUPONUMERO;
                const instalacaoControle =
                    feature.properties.OCTRLINSTALCONTROLE;

                if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "corredor"
                    )
                ) {
                    popupColor = "orange";
                } else if (
                    feature.properties.OINTERSECAOSITUACAO ===
                    "IntersecaoSemaforizada"
                ) {
                    popupColor = "green";
                } else if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "adjacente"
                    )
                ) {
                    popupColor = "green";
                } else if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "nova"
                    )
                ) {
                    popupColor = "cornflowerblue";
                } else if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "adversa"
                    )
                ) {
                    popupColor = "grey";
                } else {
                    popupColor = "brown";
                }

                if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "principal"
                    )
                ) {
                    popupIconUrl = legendIcons[0];
                } else if (
                    feature.properties.OINTERSECAOSITUACAO ===
                    "IntersecaoSemaforizada"
                ) {
                    popupIconUrl = legendIcons[11];
                } else if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "conjugado"
                    )
                ) {
                    popupIconUrl = legendIcons[1];
                } else if (
                    feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                        "naosem"
                    )
                ) {
                    popupIconUrl = legendIcons[10];
                } else {
                    popupIconUrl = legendIcons[4];
                }
                return {
                    logradouro,
                    bairroZona,
                    rede,
                    interligacao,
                    layerType,
                    numInterseccao,
                    modelo,
                    id,
                    anel,
                    grupo,
                    instalacaoControle,
                    popupColor,
                    popupIconUrl,
                };
            }
        }
        // viabilidade semaforica (Intersecao em Avaliação)
        if (layerType === "viabilidadeSem") {
            popupIconUrl = legendIcons[14];
            popupColor = "lightgreen";
            return {
                logradouro,
                popupColor,
                popupIconUrl,
                layerType,
                numInterseccao,
            };
        }

        // rotatorias ou contagens
        if (layerType === "contagemVeic") {
            popupIconUrl = legendIcons[2];
            popupColor = "blue";
            const thisLayerType = "interseccaoContOD";
            return {
                logradouro,
                popupColor,
                popupIconUrl,
                numInterseccao,
                layerType: thisLayerType,
            };
        } else if (layerType === "contagemOD") {
            popupIconUrl = legendIcons[12];
            popupColor = "purple";
            const thisLayerType = "interseccaoContOD";
            return {
                logradouro,
                popupColor,
                popupIconUrl,
                numInterseccao,
                layerType: thisLayerType,
            };
        } else if (
            feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                "rotatoria"
            )
        ) {
            popupIconUrl = legendIcons[3];
            popupColor = "purple";
            const thisLayerType = "interseccaoContOD";
            return {
                logradouro,
                popupColor,
                popupIconUrl,
                numInterseccao,
                layerType: thisLayerType,
            };
        }
        // popup apenas para proj interligacao e subtrecho
    } else if (
        ehProjUrb ||
        ehRotasCoord ||
        ehRedeInter ||
        ehRotasDes ||
        ehTopografia ||
        ehAreaSim
    ) {
        const layerType = ehRedeInter
            ? "Projeto por Interligação"
            : ehProjUrb
            ? "Projeto por Subtrecho"
            : ehRotasCoord
            ? "Banda de Coordenação"
            : ehAreaSim
            ? "Área de Simulação"
            : ehTopografia
            ? "Topografia"
            : "Rota com Desempenho";
        const layerName = feature.properties.IGUNOME;
        const popupColor = ehRedeInter
            ? "Black"
            : ehProjUrb
            ? "DimGrey"
            : ehRotasCoord
            ? "Purple"
            : ehAreaSim
            ? "Orange"
            : ehTopografia
            ? "Blue"
            : "Brown"; // rotas desempenho
        const popupIconUrl = ehRedeInter
            ? legendIcons[5]
            : ehProjUrb
            ? legendIcons[6]
            : ehRotasCoord
            ? legendIcons[7]
            : ehAreaSim
            ? legendIcons[13]
            : ehTopografia
            ? legendIcons[16]
            : legendIcons[8];
        return {
            layerType,
            layerName,
            popupColor,
            popupIconUrl,
        };
    } else if (feature.properties.IGUCAMADA === "RotasDeOnibus") {
        const layerType = "Linha de Ônibus";
        const popupColor = "BurlyWood";
        const popupIconUrl = legendIcons[9];
        const layerName = feature.properties.IGUNOME;
        const layerDesc =
            feature.properties.name + " (" + feature.properties.sentido + ")";
        return {
            layerType,
            popupColor,
            popupIconUrl,
            layerName,
            layerDesc,
        };

        // pontos de onibus
    } else if (layerType === "pontosOnibus") {
        const data = {
            popupColor: "gray",
            popupIconUrl: legendIcons[15],
            layerType: "pontosOnibus",
            numInterseccao: feature.properties.IGUNOME,
            logradouro: feature.properties.OENDLOCALP,
            id: feature.properties.OID,
        };
        // console.log(data);
        return data;
    }
};

export { getPopupData };
