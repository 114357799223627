import React from "react";

const IguDashboardItem = ({ icon, desc, qtd, color }) => {
    return (
        <div className={`igu-progress-dashboard-item-container dashboard-item-${color}`}>
            <div className="igu-progress-dashboard-item-left">
                <div className="igu-progress-dashboard-item-icon">
                    {icon}
                </div>
                <div className="igu-progress-dashboard-item-text">
                    {desc}
                </div>
            </div>
            <div className="igu-progress-dashboard-item-qtd">
                {qtd}
            </div>
        </div>
    );
};

export default IguDashboardItem;