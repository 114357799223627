import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { UserContext } from "../../common/contexts/user-context";
import PrivateRoute from "./PrivateRoute";
import RoleBasedRoute from "./RoleBasedRoute";
import NotFoundPage from "../../common/NotFoundPage";
import MapPage from "../../mainMap/MapPage";
import UsersPage from "../../adminTools/UsersPage";
import AddUserPage from "../../adminTools/AddUserPage";
import EditUserPage from "../../adminTools/EditUserPage";
import CitiesPage from "../../adminTools/CitiesPage";
import AddCityPage from "../../adminTools/AddCityPage";
import EditCityPage from "../../adminTools/EditCityPage";
import IguDocsPage from "../../otherTools/IguDocsPage";
import LogsPage from "../../adminTools/LogsPage";
import RelatoriosPage from "../../otherTools/RelatoriosPage";
import MedicoesPage from "../../otherTools/MedicoesPage";
import IguDashboardPage from "../../otherTools/IguDashboardPage";
import LoginPage from "../LoginPage";
import IndexPage from "../IndexPage";

const AppRouter = () => {
    const userContext = useContext(UserContext);
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <MapPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/docs/:layerType/:elementName/:cityID"
                element={
                    <PrivateRoute>
                        <IguDocsPage />
                    </PrivateRoute>
                }
            />
            {!userContext.isLoggedIn && (
                <Route path="/login" element={<LoginPage />} />
            )}
            {userContext.isLoggedIn && (
                <Route
                    path="/login"
                    element={<Navigate replace to="/inicio" />}
                />
            )}
            <Route
                path="/inicio"
                element={
                    <RoleBasedRoute requiredRole="user">
                        <IndexPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/relatorios/:id"
                element={
                    <PrivateRoute>
                        <RelatoriosPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/medicoes/:id"
                element={
                    <PrivateRoute>
                        <MedicoesPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin/users"
                element={
                    <RoleBasedRoute requiredRole="admin">
                        <UsersPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/user/add"
                element={
                    <RoleBasedRoute requiredRole="admin">
                        <AddUserPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/cities"
                element={
                    <RoleBasedRoute requiredRole="colab">
                        <CitiesPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/city/add"
                element={
                    <RoleBasedRoute requiredRole="editor">
                        <AddCityPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/user/:id/edit"
                element={
                    <RoleBasedRoute requiredRole="editor">
                        <EditUserPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/city/:id/edit"
                element={
                    <RoleBasedRoute requiredRole="editor">
                        <EditCityPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/city/:id/dashboard"
                element={
                    <RoleBasedRoute requiredRole="colab">
                        <IguDashboardPage />
                    </RoleBasedRoute>
                }
            />
            <Route
                path="/admin/logs"
                element={
                    <RoleBasedRoute requiredRole="admin">
                        <LogsPage />
                    </RoleBasedRoute>
                }
            />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRouter;
