import React, { useMemo } from "react";
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../common/contexts/user-context";
import { useNavigate, useParams } from "react-router-dom";

import ImageViewer from "./components/_ImageViewer";
import JsonFileUploader from "./components/_JsonFileUploader";
import useGetBrasao from "../common/hooks/useGetBrasao";
import Loader from "../common/components/_Loader";
import useFetch from "../common/hooks/useFetch";
import Alert from "../common/components/_DismissibleAlert";

import { BsFillGeoAltFill } from "react-icons/bs";

const EditCityPage = () => {
    const isComponentMounted = useRef(true);
    const { token } = useContext(UserContext);
    const navigate = useNavigate();
    const { id: cityID } = useParams();
    const { data: brasaoData, loading: brasaoLoading } = useGetBrasao(cityID);
    const { data: cityData, loading: cityLoading } = useFetch(
        `/admin/city/${cityID}`,
        isComponentMounted,
        null
    );
    const { data: existingJsonsData, loading: existingJsonsLoading } = useFetch(
        `/admin/city/jsons/${cityID}`,
        isComponentMounted,
        null
    );

    const initLayerList = useMemo(
        () => ({
            Contagens: "",
            Coordenação: "",
            "Corredores de Ônibus": "",
            Interseções: "",
            "Informações do Projeto": "",
            "Linhas de Ônibus": "",
            "Pontos de Ônibus": "",
            "Projetos Urbanos": "",
            "Rede de Interligação": "",
            "Rede Semafórica": "",
            "Rotas de Desempenho": "",
            "Sub-Rede Semafórica": "",
            Simulações: "",
            Topografia: "",
            "Viabilidade Semafórica": "",
            Zoneamento: "",
            "Implantação Fase 1": "",
            "Implantação Fase 2": "",
            "Implantação Fase 3": "",
            "Implantação Fase 4": "",
            "Implantação Fase 5": "",
            "Implantação Fase 6": "",
            "Implantação Fase 7": "",
            "Implantação Fase 8": "",
            "Implantação Fase 9": "",
        }),
        []
    );
    const layerDict = useMemo(
        () => ({
            Contagens: "Contagens",
            Coordenação: "BandasDeCoordenacao",
            "Corredores de Ônibus": "RotasCorredor",
            Interseções: "Intersecoes",
            "Informações do Projeto": "projectInfo",
            "Linhas de Ônibus": "LinhasOnibus",
            "Pontos de Ônibus": "PontosOnibus",
            "Projetos Urbanos": "ProjetosUrbanosImplantados",
            "Rede de Interligação": "RedeInterligacao",
            "Rede Semafórica": "RedeSemaforica",
            "Rotas de Desempenho": "RotasDesempenho",
            "Sub-Rede Semafórica": "RedeSub",
            Simulações: "Simulacao",
            Topografia: "Topografia",
            "Viabilidade Semafórica": "ViabilidadeSemaforica",
            Zoneamento: "Zoneamento",
            "Implantação Fase 1": "Implantacao1",
            "Implantação Fase 2": "Implantacao2",
            "Implantação Fase 3": "Implantacao3",
            "Implantação Fase 4": "Implantacao4",
            "Implantação Fase 5": "Implantacao5",
            "Implantação Fase 6": "Implantacao6",
            "Implantação Fase 7": "Implantacao7",
            "Implantação Fase 8": "Implantacao8",
            "Implantação Fase 9": "Implantacao9",
        }),
        []
    );

    const [existingJsonLayers, setExistingJsonLayers] = useState(initLayerList);
    const [hasIssues, setHasIssues] = useState(true);
    const [cityName, setCityName] = useState("");
    const [siglaUF, setSiglaUF] = useState("");
    const [coord, setCoord] = useState("");
    const [picoDU, setPicoDU] = useState("");
    const [picoFDS, setPicoFDS] = useState("");
    const [contagem, setContagem] = useState("");
    const [intersecoes, setIntersecoes] = useState("");
    const [projUrbanos, setProjUrbanos] = useState("");
    const [projInter, setProjInter] = useState("");
    const [simulacao, setSimulacao] = useState("");
    const [zoneamento, setZoneamento] = useState("");
    const [coordenacao, setCoordenacao] = useState("");
    const [rotasDes, setRotasDes] = useState("");
    const [linhasBus, setLinhasBus] = useState("");
    const [pontosBus, setPontosBus] = useState("");
    const [corredorBus, setCorredorBus] = useState("");
    const [infoProj, setInfoProj] = useState("");
    const [redeSemaforica, setRedeSemaforica] = useState("");
    const [subRedeSemaforica, setSubRedeSemaforica] = useState("");
    const [topografia, setTopografia] = useState("");
    const [viabilidadeSem, setViabilidadeSem] = useState("");
    const [implantacao1, setImplantacao1] = useState("");
    const [implantacao2, setImplantacao2] = useState("");
    const [implantacao3, setImplantacao3] = useState("");
    const [implantacao4, setImplantacao4] = useState("");
    const [implantacao5, setImplantacao5] = useState("");
    const [implantacao6, setImplantacao6] = useState("");
    const [implantacao7, setImplantacao7] = useState("");
    const [implantacao8, setImplantacao8] = useState("");
    const [implantacao9, setImplantacao9] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("success");
    const [brasaoURL, setBrasaoURL] = useState(null);

    const setLayerContentList = {
        Contagens: setContagem,
        Coordenação: setCoordenacao,
        "Corredores de Ônibus": setCorredorBus,
        Interseções: setIntersecoes,
        "Informações do Projeto": setInfoProj,
        "Linhas de Ônibus": setLinhasBus,
        "Pontos de Ônibus": setPontosBus,
        "Projetos Urbanos": setProjUrbanos,
        "Rede de Interligação": setProjInter,
        "Rede Semafórica": setRedeSemaforica,
        "Rotas de Desempenho": setRotasDes,
        "Sub-Rede Semafórica": setSubRedeSemaforica,
        Simulações: setSimulacao,
        Topografia: setTopografia,
        "Viabilidade Semafórica": setViabilidadeSem,
        Zoneamento: setZoneamento,
        "Implantação Fase 1": setImplantacao1,
        "Implantação Fase 2": setImplantacao2,
        "Implantação Fase 3": setImplantacao3,
        "Implantação Fase 4": setImplantacao4,
        "Implantação Fase 5": setImplantacao5,
        "Implantação Fase 6": setImplantacao6,
        "Implantação Fase 7": setImplantacao7,
        "Implantação Fase 8": setImplantacao8,
        "Implantação Fase 9": setImplantacao9,
    };
    const layerContentList = {
        Contagens: contagem,
        Coordenação: coordenacao,
        "Corredores de Ônibus": corredorBus,
        Interseções: intersecoes,
        "Informações do Projeto": infoProj,
        "Linhas de Ônibus": linhasBus,
        "Pontos de Ônibus": pontosBus,
        "Projetos Urbanos": projUrbanos,
        "Rede de Interligação": projInter,
        "Rede Semafórica": redeSemaforica,
        "Rotas de Desempenho": rotasDes,
        "Sub-Rede Semafórica": subRedeSemaforica,
        Simulações: simulacao,
        Topografia: topografia,
        "Viabilidade Semafórica": viabilidadeSem,
        Zoneamento: zoneamento,
        "Implantação Fase 1": implantacao1,
        "Implantação Fase 2": implantacao2,
        "Implantação Fase 3": implantacao3,
        "Implantação Fase 4": implantacao4,
        "Implantação Fase 5": implantacao5,
        "Implantação Fase 6": implantacao6,
        "Implantação Fase 7": implantacao7,
        "Implantação Fase 8": implantacao8,
        "Implantação Fase 9": implantacao9,
    };
    // console.log(brasaoLoading, cityLoading, existingJsonsLoading)

    useEffect(() => {
        if (alertMessage.includes("Cidade atualizada")) {
            setTimeout(() => {
                navigate("/admin/cities");
            }, 1000);
        }
    }, [alertMessage, navigate]);

    useEffect(() => {
        if (existingJsonsData) {
            Object.keys(initLayerList).forEach((key) => {
                initLayerList[key] = existingJsonsData[layerDict[key]];
            });
            setExistingJsonLayers(initLayerList);
        }
        if (cityData) {
            setCityName(cityData.name);
            setSiglaUF(cityData.siglaUF);
            setCoord(cityData.coord.join(","));
            setPicoDU(cityData.picoDU.join(","));
            setPicoFDS(cityData.picoFDS.join(","));
        }
        if (brasaoData) {
            setBrasaoURL(brasaoData);
        }
    }, [existingJsonsData, cityData, brasaoData, initLayerList, layerDict]);

    const cancelSubmitHandler = () => {
        navigate("/admin/cities");
    };

    const formSubmitHandler = (e) => {
        e.preventDefault();
        const sendJson = (layerContent, layerType) => {
            let hasNewContent = false;
            let body;
            if (layerContent === "") {
                // console.log("no content")
                body = { content: null, type: layerType };
                hasNewContent = true;
            } else if (layerContent.content === "existing content") {
                body = { content: "existing content" };
            } else {
                body = JSON.parse(layerContent);
                body["type"] = layerType;
                hasNewContent = true;
            }
            if (hasNewContent) {
                fetch(
                    process.env.REACT_APP_API_ENDPOINT +
                        `/admin/city/edit/${cityData._id}`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(body),
                    }
                );
            }
        };

        setAlertMessageType("secondary");
        setAlertMessage(`Atualizando a cidade ${cityName}...`);

        // add all jsons
        Object.keys(layerContentList).forEach((layer) => {
            sendJson(layerContentList[layer], layerDict[layer]);
        });

        // update city
        fetch(
            process.env.REACT_APP_API_ENDPOINT +
                `/admin/city/edit/${cityData._id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    siglaUF,
                    coord,
                    picoDU,
                    picoFDS,
                }),
            }
        ).then((res) => {
            if (res.status === 200) {
                setAlertMessageType("success");
                setAlertMessage(`Cidade atualizada: ${cityName}.`);
            } else if (res.status === 500) {
                setAlertMessageType("danger");
                setAlertMessage(
                    "Erro no formato dos dados. Verifique os campos de coordenada e hora-pico."
                );
            } else if (res.status === 501) {
                setAlertMessageType("danger");
                setAlertMessage("Erro no servidor. Tente novamente.");
            }
        });
    };

    const uploadersGrid = () => {
        return Object.keys(existingJsonLayers).map((layerType, index) => (
            <div className="col-md-4 uploader-widget" key={index}>
                <JsonFileUploader
                    tipo={layerType}
                    existingJson={existingJsonLayers[layerType]}
                    setContent={setLayerContentList[layerType]}
                    city={cityName}
                    setHasIssues={setHasIssues}
                />
            </div>
        ));
    };

    return cityLoading || brasaoLoading || existingJsonsLoading ? (
        <Loader />
    ) : (
        <>
            {alertMessage && (
                <Alert message={alertMessage} type={alertMessageType} />
            )}
            <div className="container edit-city-container">
                <form onSubmit={formSubmitHandler} className="edit-city-form">
                    <div className="form-row edit-form-header">
                        Editar Cidade
                        <div className="edit-city-header-buttons-div">
                            <button
                                className="btn-editCity-form btn-warning"
                                onClick={cancelSubmitHandler}
                            >
                                Voltar
                            </button>
                            <button
                                className="btn-editCity-form btn-primary"
                                type="submit"
                                disabled={hasIssues}
                            >
                                Atualizar
                            </button>
                        </div>
                        <hr></hr>
                    </div>

                    <div className="edit-city-form-first-row">
                        <div className="edit-city-name-and-brasao">
                            <div className="brasao-viewer-widget">
                                <ImageViewer imagePath={brasaoURL} />
                            </div>
                            <div id="edit-city-cityName">{cityName}</div>
                        </div>
                        <div className="edit-city-form-g1-g2">
                            <div className="edit-city-form-g1">
                                <div className="input-group mb-3 edit-city-cityUF">
                                    <span className="input-group-text edit-city-form-label">
                                        UF
                                    </span>
                                    <input
                                        type="text"
                                        name="uf"
                                        placeholder=" sigla"
                                        value={siglaUF}
                                        onChange={(e) =>
                                            setSiglaUF(e.target.value)
                                        }
                                        required
                                        className="form-control"
                                        aria-label="Sigla UF"
                                    />
                                </div>
                                <div className="input-group mb-3 edit-city-cityCoord">
                                    <span className="input-group-text edit-city-form-label">
                                        <BsFillGeoAltFill size={25} />
                                    </span>
                                    <input
                                        type="text"
                                        name="coord"
                                        placeholder="ex:-43.05,-23.53"
                                        value={coord}
                                        onChange={(e) =>
                                            setCoord(e.target.value)
                                        }
                                        required
                                        className="form-control"
                                        aria-label="Coordenadas"
                                    />
                                </div>
                            </div>
                            <div className="edit-city-form-g2">
                                <div className="input-group mb-3 edit-city-picoDU">
                                    <span className="input-group-text edit-city-form-label">
                                        DU
                                    </span>
                                    <input
                                        type="text"
                                        name="picoDU"
                                        placeholder="ex: 07:00,09:00"
                                        value={picoDU}
                                        onChange={(e) =>
                                            setPicoDU(e.target.value)
                                        }
                                        className="form-control"
                                        aria-label="Pico DU"
                                    />
                                </div>
                                <div className="input-group mb-3 edit-city-picoFDS">
                                    <span className="input-group-text edit-city-form-label">
                                        FDS
                                    </span>
                                    <input
                                        type="text"
                                        name="picoFDS"
                                        placeholder="ex: 07:00,09:00"
                                        value={picoFDS}
                                        onChange={(e) =>
                                            setPicoFDS(e.target.value)
                                        }
                                        className="form-control"
                                        aria-label="Pico FDS"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="edit-city-form-uploaders-grid row">
                        {uploadersGrid()}
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditCityPage;
