import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import { FaChartLine, FaFileAlt, FaBookmark } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import DeleteConfirmation from "./_DeleteConfirmation";
import { UserContext } from "../../common/contexts/user-context";
import { CityContext } from "../../common/contexts/city-context";
import useGetBrasao from "../../common/hooks/useGetBrasao";
import hasPermission from "../../common/utils/acessControl";

const CityWidget = ({
    city,
    cities,
    setCities,
    setAlertMessage,
    setAlertMessageType,
}) => {
    const { setCityID } = useContext(CityContext);
    const navigate = useNavigate();
    const { role, token } = useContext(UserContext);
    const [brasaoURL, setBrasaoURL] = useState(null);
    const { data: brasaoData, loading: brasaoLoading } = useGetBrasao(city._id);
    const [modalMessage, setModalMessage] = useState("");
    const [deleteCity, setDeleteCity] = useState({});
    const [displayConfirmationModal, setDisplayConfirmationModal] =
        useState(false);

    useEffect(() => {
        if (!brasaoLoading) {
            setBrasaoURL(brasaoData);
        }
    }, [brasaoData]);

    const cityClick = (city) => {
        setCityID(city._id);
        navigate("/");
    };

    const editButtonClick = (city) => {
        navigate(`/admin/city/${city._id}/edit`);
    };

    const dashboardButtonClick = (city) => {
        navigate(`/admin/city/${city._id}/dashboard`);
    };

    const relatorioButtonClick = (city) => {
        window.open(`/relatorios/${city._id}`, "_blank");
    };

    const medicoesButtonClick = (city) => {
        window.open(`/medicoes/${city._id}`, "_blank");
    };

    const deleteButtonClick = (city) => {
        setModalMessage(`Deseja deletar a cidade ${city.name}?`);
        setDeleteCity(city);
        setDisplayConfirmationModal(true);
    };

    const submitDelete = () => {
        setAlertMessage("");
        fetch(
            process.env.REACT_APP_API_ENDPOINT +
                `/admin/city/${deleteCity._id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then((res) => {
            if (res.status === 200) {
                setAlertMessageType("success");
                setAlertMessage(`${deleteCity.name} deletada.`);
                setCities(cities.filter((u) => u._id !== deleteCity._id));
            } else if (res.status === 409 || res.status === 403) {
                setAlertMessageType("danger");
                setAlertMessage(
                    `Não foi possível deletar a cidade ${deleteCity.name}. Há usuários dessa cidade no sistema.`
                );
            }
            setDisplayConfirmationModal(false);
        });
    };

    return (
        <div className="city-widget-outter-container">
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Medições</Tooltip>}
            >
                <Button
                    className="btn-info btn-city-widget btn-medicoes-city-widget"
                    type="button"
                    onClick={() => medicoesButtonClick(city)}
                >
                    <FaBookmark
                        className="btn-city-widget-medicoes-icon"
                        size={15}
                    />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Relatórios</Tooltip>}
            >
                <Button
                    className="btn-success btn-city-widget btn-relatorio-city-widget"
                    type="button"
                    onClick={() => relatorioButtonClick(city)}
                >
                    <FaFileAlt
                        className="btn-city-widget-relatorio-icon"
                        size={15}
                    />
                </Button>
            </OverlayTrigger>
            {hasPermission(role, "colab") && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Dashboard</Tooltip>}
                >
                    <Button
                        className="btn-warning btn-city-widget btn-dashboard-city-widget"
                        type="button"
                        onClick={() => dashboardButtonClick(city)}
                    >
                        <FaChartLine
                            className="btn-city-widget-dashboard-icon"
                            size={18}
                        />
                    </Button>
                </OverlayTrigger>
            )}
            {hasPermission(role, "editor") && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Editar</Tooltip>}
                >
                    <Button
                        className="btn-primary btn-city-widget btn-edit-city-widget"
                        type="button"
                        onClick={() => editButtonClick(city)}
                    >
                        <BsFillPencilFill
                            className="btn-city-widget-edit-icon"
                            size={18}
                        />
                    </Button>
                </OverlayTrigger>
            )}
            {hasPermission(role, "editor") && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Deletar</Tooltip>}
                >
                    <Button
                        className="btn-danger btn-city-widget btn-delete-city-widget"
                        type="button"
                        onClick={() => deleteButtonClick(city)}
                    >
                        <BsFillTrashFill
                            className="btn-city-widget-delete-icon"
                            size={18}
                        />
                    </Button>
                </OverlayTrigger>
            )}
            <a
                className="city-widget-content"
                href="#/"
                onClick={() => cityClick(city)}
            >
                <div className="city-widget-brasao">
                    <img
                        src={brasaoURL}
                        alt="(brasão)"
                        width="75"
                        height="auto"
                    />
                </div>
                <div className="cities-page-city-widget-cityName">
                    {city.name}
                </div>
            </a>
            <DeleteConfirmation
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={() => setDisplayConfirmationModal(false)}
                message={modalMessage}
            />
        </div>
    );
};

export default CityWidget;
