import React, { useContext } from "react";
import Igu from "./components/_Igu";
import { CityContext } from "../common/contexts/city-context";

const MapPage = () => {
    const cityCtx = useContext(CityContext);

    return (
        cityCtx.cityData && (
            <Igu cityID={cityCtx.cityData._id} cityData={cityCtx.cityData} />
        )
    );
};

export default MapPage;
