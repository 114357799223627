import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from "../../common/contexts/user-context"
import hasPermission from '../../common/utils/acessControl';

const RoleBasedRoute = (props) => {
    const requiredRole = props.requiredRole;
    const { role } = useContext(UserContext);
    // console.log(role, requiredRole)
    return (
        hasPermission(role, requiredRole) ? props.children : 
        <Navigate to={"/"} />
    )
};

export default RoleBasedRoute