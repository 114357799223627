import React from "react";
import { useTranslation } from "react-i18next";
import Flag from "../../common/components/_Flag";

const LanguageSwitch = () => {
    const { i18n } = useTranslation();

    function handleChangeLanguage(language) {
        i18n.changeLanguage(language);
    }

    const selectedLanguage = i18n.language;
    return (
        <div className="flags-container">
            <Flag
                image="/img/brasil-flag.svg"
                isSelected={selectedLanguage === "pt-BR"}
                onClick={() => handleChangeLanguage("pt-BR")}
            />
            <Flag
                image="/img/spain-flag.svg"
                isSelected={selectedLanguage === "es-ES"}
                onClick={() => handleChangeLanguage("es-ES")}
            />
        </div>
    );
};

export default LanguageSwitch;
