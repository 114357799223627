import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserContext = React.createContext([{
    token: null,
    isLoggedIn: false,
    role: 'user',
    cityID: null,
    login: (token) => { },
    logout: () => { }
}, () => { }])

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = expirationTime;
    const remainingDuration = adjExpirationTime - currentTime;

    // returns remaining time in timestamp format
    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');
    const storedRole = localStorage.getItem('userRole');
    const storedCityID = localStorage.getItem('cityID')
    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 5000) {
        localStorage.removeItem('token');
        localStorage.removeItem('cityID');
        localStorage.removeItem('userRole');
        localStorage.removeItem('expirationTime');
        return null;
    }

    return {
        token: storedToken,
        duration: remainingTime,
        userRole: storedRole,
        cityID: storedCityID
    }
}

let logoutTimer;

const UserProvider = (props) => {
    const localUserData = retrieveStoredToken();
    const initialToken = localUserData ? localUserData.token : null;
    const initialUserRole = localUserData ? localUserData.userRole : 'user';
    const initialCityID = localUserData ? localUserData.cityID : null;
    const [[token, role, cityID], setCtxParams] = useState([initialToken, initialUserRole, initialCityID]);
    const userIsLoggedIn = !!token;
    const navigate = useNavigate();

    const logoutHandler = () => {
        setCtxParams([null, '', null]);
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem("cityID");
        localStorage.setItem("logout", Date.now());

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
        navigate('/');
    };

    const loginHandler = (token, userRoleFromDB, expirationTime, userCityID) => {

        setCtxParams([token, userRoleFromDB, userCityID]);
        localStorage.setItem('token', token);
        localStorage.setItem('userRole', userRoleFromDB);
        localStorage.setItem('expirationTime', expirationTime);
        localStorage.setItem('cityID', userCityID);
    };

    // Sincroniza o logout
    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            window.location.reload()
        }
    }, [])

    useEffect(() => {
        window.addEventListener("storage", syncLogout)
        return () => {
            window.removeEventListener("storage", syncLogout)
        }
    }, [syncLogout])

    const contextValues = {
        token,
        role,
        isLoggedIn: userIsLoggedIn,
        cityID,
        login: loginHandler,
        logout: logoutHandler,
        retrieveStoredToken
    }

    return (
        <UserContext.Provider value={contextValues}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }