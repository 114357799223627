import React from 'react';


const IguMapPopup = (props) => {

    const userCity = props.city;

    return (
        <>
            <h3 className="popover-title iguPopupTitulo" >
                <img src='/img/iguTxtEscIGU.svg' height="48" width="auto" alt="IGU" />
            </h3 >
            <div className="iguPopupTextoNome text-center" >
                {`${userCity.name}/${userCity.siglaUF}`}
            </div >
            <div className="iguPopupTextoNome">
                <img src={props.brasaoUrl} width="96" height="auto" alt='brasao'/>
            </div>
        </>
    )
}

export default IguMapPopup;