import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { FaChartLine } from 'react-icons/fa';
import { 
    BsFillSignpost2Fill, BsCameraVideoFill, BsFillCursorFill,
    BsArrowRepeat, BsIntersect, BsHddNetworkFill, BsFileEarmarkPlay,
    BsFillMapFill, BsFillSignpostSplitFill, BsReceipt
} from 'react-icons/bs';
import Button from 'react-bootstrap/Button';

import IguDashboardItem from "./components/_IguDashboardItem";
import useFetch from "../common/hooks/useFetch";
import useGetBrasao from "../common/hooks/useGetBrasao";

const IguDashboardPage = () => {
    const isComponentMounted = useRef(true);
    const { id: cityID } = useParams();
    const [dashboardData, setDashboardData] = useState([])
    const { data } = useFetch(`/admin/city/${cityID}/dashboard`, isComponentMounted, [])
    const [brasaoURL, setBrasaoURL] = useState(null);
    const { data: brasaoData } = useGetBrasao(cityID);
    const navigate = useNavigate();

    // console.log(data)
    const getItemColor = (ItemType) => {
        let itemColor;
        switch (ItemType) {
            case 'Intersecoes':
                itemColor = 'green'
                break;
            case 'ContagensVeic':
                itemColor = 'blue'
                break;
            case 'ContagensOD':
                itemColor = 'purple'
                break;
            case 'Simulacao':
                itemColor = 'orange'
                break;
            case 'ProjetosUrbanosImplantados':
                itemColor = 'gray'
                break;
            case 'RotasDesempenho':
                itemColor = 'brown'
                break;
            case 'BandasDeCoordenacao':
                itemColor = 'pink'
                break;
            case 'Zoneamento':
                itemColor = 'yellow'
                break;
            case 'LinhasOnibus':
                itemColor = 'cyan'
                break;
            case 'ViabilidadeSemaforica':
                itemColor = 'lightgreen'
                break;
            default:
                itemColor = 'black'
                break;
        }
        return itemColor
    };
    const itemIcon = {
        'Intersecoes' : <BsFillSignpost2Fill/>,
        'ContagensVeic' : <BsCameraVideoFill />,
        'ContagensOD' : <BsArrowRepeat />,
        'RotasDesempenho' : <BsFillCursorFill />,
        'Simulacao' : <BsFileEarmarkPlay />,
        'ProjetosUrbanosImplantados' : <BsIntersect />,
        'BandasDeCoordenacao' : <BsHddNetworkFill />,
        'Zoneamento': <BsFillMapFill />,
        'LinhasOnibus': <BsFillSignpostSplitFill />,
        'ViabilidadeSemaforica': <BsReceipt />
    }
    const itemDesc = {
        'Intersecoes' : 'Intersecções',
        'ContagensVeic' : 'Contagens Veic.',
        'ContagensOD' : 'Contagens OD',
        'RotasDesempenho' : 'Rotas GPS',
        'Simulacao' : 'Simulações',
        'ProjetosUrbanosImplantados' : 'Projetos',
        'BandasDeCoordenacao' : 'Bandas de Coord.',
        'Zoneamento' : 'Zoneamento',
        'LinhasOnibus': 'Linhas de Ônibus',
        'ViabilidadeSemaforica' : 'Viabilidade Sem.',
    };

    useEffect(() => {
        if (data) {
            setDashboardData(data);
        };
    },[data])

    useEffect(() => {
        if (brasaoData) {
            setBrasaoURL(brasaoData);
        }
    }, [brasaoData])
 
    const dashboardItems = dashboardData.filter(
        item => item.type !== 'projectInfo' && item.qtd !== '000'
    ).sort((a,b) => {
        if (itemDesc[a.type] > itemDesc[b.type]) { 
            return 1
        }  else {
            return -1
        }
    }).map((item, i) => {
        return (
            <div className="col-md-6" key={i}>
                <IguDashboardItem
                    icon={itemIcon[item.type]}
                    desc={itemDesc[item.type]}
                    color={getItemColor(item.type)}
                    qtd={item.qtd}
                />
            </div>
        )
    });

    return (
        <div className="igu-dashboard-outter-container container">
            <div className="igu-dashboard-main-container">
                <div className="igu-dashboard-title">
                    <div className="igu-dashboard-title-left">
                        <div className="igu-dashboard-title-icon">
                            <FaChartLine />
                        </div>
                        <div className="igu-dashboard-title-text">
                            Dashboard
                        </div>
                    </div>
                    <div className="igu-dashboard-title-brasao">
                        <img 
                            src={brasaoURL} 
                            alt="(brasão)"
                            width="70"
                            height="auto"
                        ></img>
                    </div>
                </div>

                <div className="igu-dashboard-container row">
                    {dashboardItems}
                </div>
            </div>
            <Button 
                className="btn-warning igu-dashboard-return-button" 
                type="button" 
                onClick={() => navigate(`/admin/cities`)}
            >
                Voltar 
            </Button>
        </div>
    )
};

export default IguDashboardPage;