import React from 'react'

const IguBackground = () => {
    return (
        <div className='igu-background-img-div'>
            <img className='igu-background-img' src='/img/iguTxtClaIGU.svg' alt='background'/>
        </div>
    )
}

export default IguBackground;