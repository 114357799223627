import React, { useEffect } from 'react';

import colorList from "../utils/colorList";

const LinhasBusLegend = (props) => {
    const ListaLinhas = props.data;  

    useEffect(() => {
        const allLinhaBus = document.querySelectorAll(".linha-bus-class");
        // deselect all on load
        allLinhaBus.forEach(linha => {
            if (!linha.classList.contains("invisible-linhaBus")) {
                linha.classList.add("invisible-linhaBus");
            };
        });

        return () => {
            // deselect all on unload
            allLinhaBus.forEach(linha => {
                if (!linha.classList.contains("invisible-linhaBus")) {
                    linha.classList.add("invisible-linhaBus");
                };
            });
        };
    })

    const onChangeHandler = (e) => {
        const selectedLinhaBus = document.querySelectorAll(`.${e.target.id}-class`);
        selectedLinhaBus.forEach((linhaBus) => {
            linhaBus.classList.toggle("invisible-linhaBus")
        })
    };

    const checkAllHandler = () => {
        const allLinhaBus = document.querySelectorAll(".linha-bus-class");
        const allLinhaBusCheckboxes = document.querySelectorAll(".my-buslist-checkbox");
        const thisCheckBox = document.querySelector(".checkall-checkbox");
        
        allLinhaBusCheckboxes.forEach((checkbox) => {
            checkbox.checked = thisCheckBox.checked;
        });
        allLinhaBus.forEach(linha => {
            if (!thisCheckBox.checked) {
                if (!linha.classList.contains("invisible-linhaBus")) {
                    linha.classList.add("invisible-linhaBus");
                };
            } else {
                if (linha.classList.contains("invisible-linhaBus")) {
                    linha.classList.remove("invisible-linhaBus");
                };
            };
        });
    };

    const ListaCheckbox = ListaLinhas.map((linha, i) =>
        <div key={i} className="linha-bus-checkbox" >
            <input
                className='my-buslist-checkbox'
                type="checkbox"
                id={`${linha.linha}-${linha.sentido}`}
                name="linhas-bus"
                onChange={e => onChangeHandler(e)}
                defaultChecked={false}
            />
            <div className="linha-bus-color" style={{ "backgroundColor": colorList[linha.cor] }} />
            <label >{linha.linha} - {linha.sentido}</label>
        </div>
    )

    return (
        <div className='leaflet-control-container'>
            <div className='leaflet-top leaflet-left'>
                <div className='myMap-busLegend leaflet-control'>
                    <div className="myMap-busLegend-title">
                        <input
                            className='checkall-checkbox'
                            type="checkbox"
                            defaultChecked={false}
                            onChange={() => checkAllHandler()}
                        />
                        Linhas de ônibus
                    </div>
                    {ListaCheckbox}
                </div>
            </div>
        </div>
    )
};

export default LinhasBusLegend;