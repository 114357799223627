import React from "react";
import { Tree } from "@geist-ui/core";

import { formatTreeData } from "../utils/formatTreeDataJson";
import { useTranslation } from "react-i18next";

const CustomTree = (props) => {
    const { t } = useTranslation("projectTypes");
    const treeData = formatTreeData(props.treeData, t);
    const setFileName = props.setFileName;
    const setFileFolder = props.setFileFolder;
    const setFileExt = props.setFileExt;
    const setFileSubFolder = props.setFileSubFolder;

    const handler = (path) => {
        const pathLength = path.split("/").length;
        const pathArr = path.split("/");
        console.log(path, pathLength);
        if (pathLength === 2) {
            const [, itemName] = pathArr;
            setFileName(itemName);
            setFileFolder("");
            setFileExt(itemName.split(".").at(-1));
        } else if (pathLength === 3) {
            const [, itemFolder, itemName] = pathArr;
            setFileName(itemName);
            setFileFolder(itemFolder);
            setFileExt(itemName.split(".").at(-1));
        } else if (pathLength === 4) {
            const [, itemFolder, itemSubFolder, itemName] = pathArr;
            setFileName(itemName);
            setFileFolder(itemFolder);
            setFileExt(itemName.split(".").at(-1));
            setFileSubFolder(itemSubFolder);
        }
    };
    return <Tree initialExpand={true} onClick={handler} value={[treeData]} />;
};

export default CustomTree;
