import React from "react";

const ImageViewer = (props) => {
    const imagePath = props.imagePath;

    return (
        <img 
            className="preview-brasao-img"
            src={imagePath}
            alt="(brasão)"
            width="75" 
            height="auto"
        />
    )
}

export default ImageViewer;