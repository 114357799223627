import React from "react";
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../common/contexts/user-context";
import { useNavigate } from "react-router-dom";

import {
    BsFillPersonFill,
    BsMapFill,
    BsEnvelopeFill,
    BsShieldLockFill,
    BsFillBookmarkStarFill,
} from "react-icons/bs";
import Form from "react-bootstrap/Form";

import Alert from "../common/components/_DismissibleAlert";
import Checkbox from "../common/components/_Checkbox";
import useFetch from "../common/hooks/useFetch";

const NewUserPage = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const { data: allCityData } = useFetch("/admin/cities");
    const [checkedCities, setCheckedCities] = useState([]);
    const [email, setEmail] = useState("");
    // const [cities, setCities] = useState([]);
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("success");
    const assignedCities = useRef([]);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        if (allCityData) {
            // setCities(allCityData);
            setCheckedCities(
                allCityData.map((city) => ({ name: city.name, checked: false }))
            );
        }
    }, [allCityData]);

    useEffect(() => {
        if (alertMessage === "Usuário adicionado! Redirecionando...") {
            setTimeout(() => {
                navigate("/admin/users");
            }, 1000);
        }
    }, [alertMessage, navigate]);

    const cancelSubmitHandler = () => {
        navigate("/admin/users");
    };

    const getAssignedCitiesFunction = () => {
        let checkedCitiesList = checkedCities
            .filter((city) => city.checked === true)
            .map((e) => e.name);
        if (checkedCitiesList.length === allCityData.length) {
            checkedCitiesList = ["all"];
        }
        return checkedCitiesList;
    };

    const handleCheckboxClick = (e) => {
        if (e.target.nextSibling.data === "Selecionar todas") {
            if (!allChecked) {
                setCheckedCities(
                    checkedCities.map((city) => {
                        city.checked = true;
                        return city;
                    })
                );
                setAllChecked(true);
                assignedCities.current = ["all"];
            } else {
                setCheckedCities(
                    checkedCities.map((city) => {
                        city.checked = false;
                        return city;
                    })
                );
                setAllChecked(false);
                assignedCities.current = [];
            }
        } else {
            setCheckedCities(
                checkedCities.map((city) => {
                    if (city.name === e.target.nextSibling.data) {
                        city.checked = !city.checked;
                    }
                    return city;
                })
            );
            assignedCities.current = getAssignedCitiesFunction();
        }
        // if (e.target.nextSibling.data === "Selecionar todas") {
        //     if (!allChecked) {
        //         assignedCities.current = ['all'];
        //         setAllChecked(true);
        //     } else {
        //         assignedCities.current = [];
        //         setAllChecked(false);
        //     }
        // } else {
        //     if (!assignedCities.current.includes(e.target.nextSibling.data)) {
        //         assignedCities.current.push(e.target.nextSibling.data);
        //         if (assignedCities.current.length === cities.length) {
        //             assignedCities.current = ['all'];
        //         };
        //     } else {
        //         if (assignedCities.current.includes('all')) {
        //             assignedCities.current = cities.filter(
        //                 city => city.name != e.target.nextSibling.data
        //             ).map(city => city.name)
        //         } else {
        //             assignedCities.current.splice(
        //                 assignedCities.current.indexOf(e.target.nextSibling.data), 1
        //             );
        //         }
        //     };
        // }
    };

    const handleSelectChange = (e) => {
        setRole(e.target.value);
    };

    const formSubmitHandler = (e) => {
        setAlertMessage("");
        e.preventDefault();
        fetch(process.env.REACT_APP_API_ENDPOINT + `/admin/user/add/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            },
            body: JSON.stringify({
                username: email,
                password,
                fullname: name,
                role,
                access: assignedCities.current,
            }),
        }).then((res) => {
            if (res.status === 200) {
                setAlertMessageType("success");
                setAlertMessage("Usuário adicionado! Redirecionando...");
            } else if (res.status === 900) {
                setAlertMessageType("danger");
                setAlertMessage(
                    "Email já registrado. Favor usar outro email. "
                );
            } else if (res.status === 500) {
                setAlertMessageType("warning");
                setAlertMessage(
                    "Favor preencher todos os campos do formulário. "
                );
            }
        });
    };

    const cityList = checkedCities
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else {
                return 1;
            }
        })
        .map((city, id) => (
            <div className="col-4" key={id}>
                <Checkbox
                    value={city.checked}
                    label={city.name}
                    onChange={(e) => handleCheckboxClick(e)}
                />
            </div>
        ));

    return (
        <>
            {alertMessage && (
                <Alert message={alertMessage} type={alertMessageType} />
            )}
            <div className="container edit-container">
                <form onSubmit={formSubmitHandler}>
                    <div className="form-row form-header">
                        <h2 className="form-header-text">Novo Usuário</h2>
                        <div className="buttons-div">
                            <button
                                className="btn-edit-form btn-danger"
                                onClick={cancelSubmitHandler}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn-edit-form btn-success"
                                type="submit"
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <div className="form-row">
                        <div className="input-group mb-3 edit-input-field">
                            <span className="input-group-text">
                                <BsFillPersonFill size={20} />
                            </span>
                            <input
                                type="text"
                                name="name"
                                placeholder="Nome do usuário"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Username"
                            />
                        </div>
                        <div className="input-group mb-3 edit-input-field">
                            <span className="input-group-text">
                                <BsFillBookmarkStarFill size={20} />
                            </span>
                            <Form.Select
                                aria-label="Select role"
                                className="form-control"
                                onChange={(e) => handleSelectChange(e)}
                                required
                            >
                                <option>Selecione o cargo</option>
                                <option value="user">
                                    Usuário (clientes, visualização de APENAS
                                    UMA cidade)
                                </option>
                                <option value="colab">
                                    Colaborador (usuários internos, visualização
                                    de cidade(s))
                                </option>
                                <option value="editor">
                                    Editor (usuários internos, edição de
                                    cidade(s))
                                </option>
                                <option value="admin">
                                    Gestor (acesso total)
                                </option>
                            </Form.Select>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">
                                <BsEnvelopeFill size={20} />
                            </span>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email do usuário"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Email"
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">
                                <BsShieldLockFill size={20} />
                            </span>
                            <input
                                type="password"
                                name="password"
                                placeholder="Senha do usuário"
                                defaultValue=""
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control"
                                aria-label="Password"
                            />
                        </div>
                        <div className="add-user-page-checkboxes-container row">
                            <div className="add-user-page-checkboxes-title-container">
                                <div className="add-user-page-checkboxes-title-icon">
                                    <BsMapFill size={20} />
                                </div>
                                <div className="add-user-page-checkboxes-title">
                                    Selecione a(s) cidade(s) que deseja dar
                                    acesso
                                </div>
                                <Checkbox
                                    value={allChecked}
                                    label="Selecionar todas"
                                    onChange={(e) => handleCheckboxClick(e)}
                                />
                            </div>
                            <hr></hr>
                            {cityList}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default NewUserPage;
