import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import {
    BsFillHouseFill, BsArrowsFullscreen, BsMapFill, BsCardText,
    BsFillSignpost2Fill, BsCameraVideoFill, BsStickiesFill, BsFillCursorFill,
    BsFillBinocularsFill, BsFillEyeFill, BsFillEyeSlashFill
} from 'react-icons/bs'
import { AiOutlineTable } from 'react-icons/ai'

const ModalInfoGeral = (props) => {
    const show = props.show;
    const handleClose = props.handleClose;

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='info-modal-title'>Informações Gerais</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src="/img/iguIconeIGU.svg" height="56" width="auto" align="left" className='info-modal-icone-igu' alt='igu-ico'/>
                <h1 style={{fontSize:'5rem'}}>Interface Gráfica Urbana</h1>
                {/* <h4>Desenvolvido por <a href="http://www.tranzum.com.br/" target="Tranzum">Tranzum Planejamento e Consultoria de Trânsito Ltda</a>. </h4> */}
                <br/>
                <h2>Botões de Acesso Rápido</h2>
                <h4><BsFillHouseFill/><b> Início</b> : Vai para o ponto central do mapa.</h4>
                <h4><BsArrowsFullscreen/><b> Zoom Extendido</b> : Extende a vizualização do mapa para exibir todo o projeto.</h4>
                <h4><BsMapFill/><b> Trocar base</b> : Alterna a base do mapa entre a base com Aerofotos e a base Padrão.</h4>
                <h4><BsCardText/><b> Legenda</b> : Exibe a legenda do mapa.</h4>
                <h4><BsFillBinocularsFill/><b> Busca</b> : Exibe uma tabela com a listagem das interseções com opção de busca e filtragem de informações.</h4>
                <hr/>
                <h2>Menu Principal</h2>
                <h4><BsFillSignpost2Fill/><b> Interseções</b> : Exibe/Oculta no mapa os ícones das interseções semaforizadas.</h4>
                <h4><BsCameraVideoFill/><b> Contagens</b> : Exibe/Oculta no mapa o ícone que indica o local aonde uma contagem foi realizada.</h4>
                <h4><BsStickiesFill/><b> Projetos</b> : Exibe/Oculta no mapa os locais aonde existem projetos.</h4>
                <h4><BsFillCursorFill/><b> Rotas</b> : Exibe/Oculta no mapa, corredores de ônibus.</h4>
                <h4><AiOutlineTable/><b> Redes</b> : Exibe/Oculta no mapa as regiões de abrangência das redes semafóricas.</h4>
                {/* <h4><BsArrowDownUp/><b> Direções</b> : Exibe/Oculta no mapa os sentidos de circulação das vias.</h4> */}
                <hr/>
                <h2>Exibição de Camada</h2>
                <h4><BsFillEyeFill/> : Camada exibida no mapa.</h4>
                <h4><BsFillEyeSlashFill/> : Camada oculta no mapa.</h4>    
                    
                

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} size='lg'>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default ModalInfoGeral;