import React, { useState, useContext, useRef } from "react";

import useFetch from '../hooks/useFetch';
import { UserContext } from './user-context';
import hasPermission from "../utils/acessControl";

const CityContext = React.createContext([{
    cityData: {},
    setCityID: () => {}
}, () => { }])

const CityProvider = (props) => {
    const { role, isLoggedIn } = useContext(UserContext);

    return isLoggedIn ? 
        ( hasPermission(role, 'colab') ? 
            <AdmCityProvider>{props.children}</AdmCityProvider> 
            : 
            <UserCityProvider>{props.children}</UserCityProvider>
        ) 
        : 
        <UnloggedCityProvider>{props.children}</UnloggedCityProvider>
}

const AdmCityProvider = (props) => {
    const { cityID: userCityID } = useContext(UserContext)
    const isComponentMounted = useRef(true);
    const [cityID, setCityID] = useState(userCityID);
    const { data: admCityData } = useFetch(`/admin/city/${cityID}`, isComponentMounted, null)

    const contextValues = {
        setCityID,
        cityData: admCityData
    }
    return (
        <CityContext.Provider value={contextValues}>
            {props.children}
        </CityContext.Provider>
    )
}

const UserCityProvider = (props) => {
    const isComponentMounted = useRef(true);
    const { data: userCityData } = useFetch("/users/city", isComponentMounted, null)
    const contextValues = {
        cityData: userCityData,
    }

    return (
        <CityContext.Provider value={contextValues}>
            {props.children}
        </CityContext.Provider>
    )
}

const UnloggedCityProvider = (props) => {
    return (
        <CityContext.Provider value={{}}>
            {props.children}
        </CityContext.Provider>
    )
}

export { CityContext, CityProvider }