import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { BsFillExclamationCircleFill, BsCheckCircleFill } from 'react-icons/bs'

const ModalAddJsonFile = (props) => {
    const show = props.show;
    const handleClose = props.handleClose;
    const nItems = props.qtd;
    const layerType = props.layerType;
    const alerts = props.alerts?.map((alert, index) => (
        <div key={index}>
            <BsFillExclamationCircleFill color='orange'/> {alert}
        </div>
    ));
    
    const info = (
        <>
            {!alerts?.length && (
                <>
                    <div className='json-file-problems'>
                        <BsCheckCircleFill color='green'/> Nenhum problema encontrado. 
                    </div>
                    <br></br>
                </>)
            }
            {nItems && <div className='json-file-details'>
                {layerType} : {nItems}
            </div>}
            <br></br>
            {!!alerts?.length && <div className='json-file-alerts'>
                {alerts}
            </div>}
        </>
    )

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='add-json-info-modal-title'>Informações do arquivo de {layerType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>
                    {info}
                </h2>
                 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} size='lg'>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default ModalAddJsonFile;