import React, { useState } from "react";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
    BsFillEyeFill,
    BsFillEyeSlashFill,
    BsBoxArrowInUpRight,
} from "react-icons/bs";

// import useToggle from './../hooks/useToggle'

const IguToolbarB2 = (props) => {
    const [show, setShow] = useState(false);
    const icon = props.icon;
    const description = props.description;
    const categories = props.categories ? props.categories : [];
    const categoriesIcons = props.categoriesIcons ? props.categoriesIcons : [];
    const onClick = props.onClick;
    let isVisible = props.layerVisibility ? props.layerVisibility : true;
    const itensQtd = props.quantities;
    const itensQtdSum = itensQtd.reduce((a, b) => a + b, 0);
    const ehAjuda = props.ehAjuda;
    const ehTP = props.ehTP;
    const groupClick = props.groupClick;

    const showDropdown = () => {
        setShow(!show);
    };
    const hideDropdown = () => {
        setShow(false);
    };

    const categoryList = categories.map(
        (category, index) =>
            itensQtd[index] > 0 && (
                <Dropdown.Item
                    className="category-item"
                    eventKey={category}
                    key={index}
                    onClick={onClick[index]}
                >
                    {ehAjuda ? (
                        <></>
                    ) : ehTP && category === "Indicadores" ? (
                        <BsBoxArrowInUpRight />
                    ) : isVisible[index] ? (
                        <BsFillEyeFill />
                    ) : (
                        <BsFillEyeSlashFill />
                    )}
                    <span className="category-name">
                        {category} {categoriesIcons[index]}
                    </span>
                    {ehAjuda || (ehTP && category === "Indicadores") ? (
                        <></>
                    ) : (
                        <span className="category-qtd">{itensQtd[index]}</span>
                    )}
                </Dropdown.Item>
            )
    );

    return itensQtdSum > 0 ? (
        <>
            <DropdownButton
                drop="up"
                className="igu-toolbar-btn-tipo2"
                variant="secondary"
                as={ButtonGroup}
                title={
                    <>
                        {icon} {description}
                    </>
                }
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
                show={show}
            >
                {categoryList}
                <Dropdown.Divider />
                <Dropdown.Item className="category-item" onClick={groupClick}>
                    {description}
                </Dropdown.Item>
            </DropdownButton>
        </>
    ) : (
        <></>
    );
};

export default IguToolbarB2;
