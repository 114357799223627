import React from "react";
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../common/contexts/user-context";
import { useNavigate, useParams } from "react-router-dom";

import {
    BsFillPersonFill,
    BsMapFill,
    BsEnvelopeFill,
    BsShieldLockFill,
    BsFillBookmarkStarFill,
} from "react-icons/bs";
import Form from "react-bootstrap/Form";

import Loader from "../common/components/_Loader";
import useFetch from "../common/hooks/useFetch";
import Alert from "../common/components/_DismissibleAlert";
import Checkbox from "../common/components/_Checkbox";

const EditUserPage = () => {
    const userContext = useContext(UserContext);
    const isComponentMounted = useRef(true);
    const navigate = useNavigate();

    const { id } = useParams();
    const { data: userData } = useFetch(
        `/admin/user/${id}`,
        isComponentMounted,
        null
    );
    const { data: allCityData } = useFetch(
        "/admin/cities",
        isComponentMounted,
        []
    );
    const [checkedCities, setCheckedCities] = useState([]);
    const [email, setEmail] = useState("");
    const userCities = useRef([]);
    const [updatedCities, setUpdatedCities] = useState(userCities.current);
    const [role, setRole] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        if (userData && allCityData) {
            setEmail(userData.username);
            userCities.current = userData.access.includes("all")
                ? allCityData.map((city) => city.name)
                : userData.access;
            setUpdatedCities(userData.access);
            setCheckedCities(
                allCityData.map((city) => ({
                    name: city.name,
                    checked: userCities.current.includes(city.name),
                }))
            );
            setName(userData.fullname);
            setRole(userData.role);
        }
    }, [userData, allCityData]);

    const cancelSubmitHandler = () => {
        navigate("/admin/users");
    };

    const handleCheckboxClick = (e) => {
        if (e.target.nextSibling.data === "Selecionar todas") {
            if (!allChecked) {
                setCheckedCities(
                    checkedCities.map((city) => {
                        city.checked = true;
                        return city;
                    })
                );
                setAllChecked(true);
                setUpdatedCities(["all"]);
            } else {
                setCheckedCities(
                    checkedCities.map((city) => {
                        city.checked = false;
                        return city;
                    })
                );
                setAllChecked(false);
                setUpdatedCities([]);
            }
        } else {
            setCheckedCities(
                checkedCities.map((city) => {
                    if (city.name === e.target.nextSibling.data) {
                        city.checked = !city.checked;
                    }
                    return city;
                })
            );
            setUpdatedCities(getUpdatedCitiesFunction());
        }
    };

    const getUpdatedCitiesFunction = () => {
        let checkedCitiesList = checkedCities
            .filter((city) => city.checked === true)
            .map((e) => e.name);
        if (checkedCitiesList.length === allCityData.length) {
            checkedCitiesList = ["all"];
        }
        return checkedCitiesList;
    };

    const cityList = checkedCities
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else {
                return 1;
            }
        })
        .map((city, id) => (
            <div className="col-4" key={id}>
                <Checkbox
                    value={city.checked}
                    label={city.name}
                    onChange={(e) => handleCheckboxClick(e)}
                />
            </div>
        ));

    const formSubmitHandler = (e) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_API_ENDPOINT + `/admin/user/edit/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            },
            body: JSON.stringify({
                username: email,
                password,
                access: updatedCities,
                fullname: name,
                role,
            }),
        }).then((res) => {
            if (res.status === 200) {
                setAlertMessage("Usuário atualizado!");
            } else {
                console.log(res);
            }
        });
    };

    return userData && allCityData ? (
        <>
            {alertMessage && (
                <Alert message={alertMessage} type="success">
                    {" "}
                </Alert>
            )}
            <div className="container edit-container">
                <form onSubmit={formSubmitHandler}>
                    <div className="form-row form-header">
                        <h2 className="form-header-text">Editar Usuário</h2>
                        <div className="buttons-div">
                            <button
                                className="btn-edit-form btn-warning"
                                onClick={cancelSubmitHandler}
                            >
                                Voltar
                            </button>
                            <button
                                className="btn-edit-form btn-primary"
                                type="submit"
                            >
                                Atualizar
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <div className="form-row">
                        <div className="input-group mb-3 edit-input-field">
                            <span className="input-group-text">
                                <BsFillPersonFill size={20} />
                            </span>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Username"
                            />
                        </div>
                        <div className="input-group mb-3 edit-input-field">
                            <span className="input-group-text">
                                <BsFillBookmarkStarFill size={20} />
                            </span>
                            <Form.Select
                                aria-label="Select role"
                                className="form-control"
                                onChange={(e) => setRole(e.target.value)}
                                value={role}
                            >
                                <option>Selecione o cargo</option>
                                <option value="user">
                                    Usuário (clientes, visualização de APENAS
                                    UMA cidade)
                                </option>
                                <option value="user_special">
                                    Usuário Especial(clientes, mas com acesso
                                    especial)
                                </option>
                                <option value="colab">
                                    Colaborador (usuários internos, visualização
                                    de cidade(s))
                                </option>
                                <option value="editor">
                                    Editor (usuários internos, edição de
                                    cidade(s))
                                </option>
                                <option value="admin">
                                    Gestor (acesso total)
                                </option>
                            </Form.Select>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">
                                <BsEnvelopeFill size={20} />
                            </span>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Email"
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">
                                <BsShieldLockFill size={20} />
                            </span>
                            <input
                                type="password"
                                name="password"
                                defaultValue=""
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control"
                                aria-label="Password"
                            />
                        </div>
                        <div className="edit-user-page-checkboxes-container row">
                            <div className="edit-user-page-checkboxes-title-container">
                                <div className="edit-user-page-checkboxes-title-icon">
                                    <BsMapFill size={20} />
                                </div>
                                <div className="edit-user-page-checkboxes-title">
                                    Selecione a(s) cidade(s) que deseja dar
                                    acesso
                                </div>
                                <Checkbox
                                    value={allChecked}
                                    label="Selecionar todas"
                                    onChange={(e) => handleCheckboxClick(e)}
                                />
                            </div>
                            <hr></hr>
                            {cityList}
                        </div>
                    </div>
                </form>
            </div>
        </>
    ) : (
        <>
            <Loader />
        </>
    );
};

export default EditUserPage;
