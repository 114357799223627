import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import SearchModalIntersecaoTabBody from "./_SearchModalTabBody-Int";
import SearchModalContagemTabBody from "./_SearchModalTabBody-Cont";
import SearchModalProjetosTabBody from "./_SearchModalTabBody-Proj";
import { useTranslation } from "react-i18next";

const SearchModal = (props) => {
    const show = props.show;
    const handleClose = props.handleClose;
    const { t } = useTranslation("searchModal");
    const intData = props.intData.sort(
        (a, b) =>
            a.properties.OINTERSECAONUMERO > b.properties.OINTERSECAONUMERO
    );
    const contData = props.contData.sort(
        (a, b) =>
            a.properties.OINTERSECAONUMERO > b.properties.OINTERSECAONUMERO
    );
    const projData = props.projData.sort(
        (a, b) => a.properties.IGUNOME > b.properties.IGUNOME
    );
    const cityID = props.cityID;
    const myMap = props.map;

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="search-modal">
            <Card style={{ border: `1px solid green` }}>
                <Modal.Header closeButton>
                    <Card.Header className="searchModal-card-header">
                        <ul
                            className="nav nav-tabs card-header-tabs searchModal-nav-tab"
                            id="myTab"
                            role="tablist"
                        >
                            <li
                                className="searchModal-nav-item"
                                role="presentation"
                            >
                                <a
                                    className="nav-link searchModal-tabText active"
                                    id="int-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#int"
                                    aria-selected="true"
                                    role="tab"
                                    href="#interseccoes"
                                >
                                    {t("int")}
                                </a>
                            </li>
                            <li
                                className="searchModal-nav-item"
                                role="presentation"
                            >
                                <a
                                    className="nav-link searchModal-tabText"
                                    id="cont-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#cont"
                                    aria-selected="false"
                                    role="tab"
                                    href="#contagens"
                                >
                                    {t("cont")}
                                </a>
                            </li>
                            <li
                                className="searchModal-nav-item"
                                role="presentation"
                            >
                                <a
                                    className="nav-link searchModal-tabText"
                                    id="proj-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#proj"
                                    aria-selected="false"
                                    role="tab"
                                    href="#projetos"
                                >
                                    {t("proj")}
                                </a>
                            </li>
                        </ul>
                    </Card.Header>
                </Modal.Header>
                <Modal.Body>
                    <Card.Body>
                        <div className="tab-content" id="myTabContent">
                            <SearchModalIntersecaoTabBody
                                layerData={intData}
                                myMap={myMap}
                                cityID={cityID}
                            />
                            <SearchModalContagemTabBody
                                layerData={contData}
                                myMap={myMap}
                                cityID={cityID}
                            />
                            <SearchModalProjetosTabBody
                                layerData={projData}
                                myMap={myMap}
                                cityID={cityID}
                            />
                        </div>
                    </Card.Body>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="lg">
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Card>
        </Modal>
    );
};

export default SearchModal;
