import React from "react";
import { useContext, useState, useEffect } from "react"
import { UserContext } from "../common/contexts/user-context"
import { useNavigate } from "react-router-dom"

import { BsMapFill, BsFillGeoAltFill } from 'react-icons/bs'

import Alert from '../common/components/_DismissibleAlert';
import JsonFileUploader from "./components/_JsonFileUploader";

const NewUserPage = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageType, setAlertMessageType] = useState("success")
    const [cityName, setCityName] = useState("");
    const [siglaUF, setSiglaUF] = useState("");
    const [coord, setCoord] = useState("");
    const [picoDU, setPicoDU] = useState("");
    const [picoFDS, setPicoFDS] = useState("");
    const [contagem, setContagem] = useState("");
    const [intersecoes, setIntersecoes] = useState("");
    const [projUrbanos, setProjUrbanos] = useState("");
    const [projInter, setProjInter] = useState("");
    const [simulacao, setSimulacao] = useState("");
    const [zoneamento, setZoneamento] = useState("");
    const [coordenacao, setCoordenacao] = useState("");
    const [rotasDes, setRotasDes] = useState("");
    const [linhasBus, setLinhasBus] = useState("");
    const [corredorBus, setCorredorBus] = useState("");
    const [infoProj, setInfoProj] = useState("");
    const [avaliacao, setAvaliacao] = useState("");
    const [redeSemaforica, setRedeSemaforica] = useState("");
    const [subRedeSemaforica, setSubRedeSemaforica] = useState("");
    const [hasIssues, setHasIssues] = useState(true);

    useEffect(() => {
        if (alertMessage.includes("Nova cidade adicionada")) {
            setTimeout(() => {
                navigate('/admin/cities');
            }, 1000)
        }
    }, [alertMessage, navigate]);

    const cancelSubmitHandler = () => {
        navigate('/admin/cities')
    };

    const setLayerContentList = {
        "Avaliações": setAvaliacao,
        "Contagens": setContagem,
        "Coordenação": setCoordenacao,
        "Corredores de Ônibus": setCorredorBus,
        "Interseções": setIntersecoes,
        "Informações do Projeto": setInfoProj,
        "Linhas de Ônibus": setLinhasBus,
        "Projetos Urbanos": setProjUrbanos,
        "Rede de Interligação": setProjInter,
        "Rede Semafórica": setRedeSemaforica,
        "Rotas de Desempenho": setRotasDes,
        "Sub-Rede Semafórica": setSubRedeSemaforica,
        "Simulações": setSimulacao,
        "Zoneamento": setZoneamento,
    };

    const layerContentList = {
        "Avaliações": avaliacao,
        "Contagens": contagem,
        "Coordenação": coordenacao,
        "Corredores de Ônibus": corredorBus,
        "Interseções": intersecoes,
        "Informações do Projeto": infoProj,
        "Linhas de Ônibus": linhasBus,
        "Projetos Urbanos": projUrbanos,
        "Rede de Interligação": projInter,
        "Rede Semafórica": redeSemaforica,
        "Rotas de Desempenho": rotasDes,
        "Sub-Rede Semafórica": subRedeSemaforica,
        "Simulações": simulacao,
        "Zoneamento": zoneamento,
    };
    
    const formSubmitHandler = (e) => {
        // setAlertMessage("");
        e.preventDefault();
        const sendJson = (layerContent) => {
            const body = layerContent;
            fetch(
                process.env.REACT_APP_API_ENDPOINT + `/admin/city/add/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userContext.token}`
                },
                body,
            });
        };
        
        setAlertMessageType("secondary");
        setAlertMessage(`Adicionando nova cidade (${cityName})...`);

        // add all jsons
        Object.keys(layerContentList).forEach((layer) => {
            if (layerContentList[layer]) {
                sendJson(layerContentList[layer]);
            };
        });
        
        // add city
        fetch(
            process.env.REACT_APP_API_ENDPOINT + `/admin/city/add/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userContext.token}`
            },
            body: JSON.stringify({
                cityName,
                siglaUF,
                coord,
                picoDU,
                picoFDS
            }),
        }).then((res) => {
            if (res.status === 200) {
                setAlertMessageType("success");
                setAlertMessage(`Nova cidade adicionada: ${cityName}.`);
            } else if (res.status === 900) {
                setAlertMessageType("danger");
                setAlertMessage("Cidade já registrada. Edite o existente ou confira o nome.");
            } else if (res.status === 500) {
                setAlertMessageType("danger");
                setAlertMessage("Erro no formato dos dados. Verifique os campos de coordenada e hora-pico.");
            } else if (res.status === 501) {
                setAlertMessageType("danger");
                setAlertMessage("Erro no servidor. Tente novamente.");
            };
        });
    };

    const uploadersGrid = () => {
        return Object.keys(setLayerContentList).map((layerType, index) => (
            <div className="col-md-4 uploader-widget" key={index}>
                <JsonFileUploader
                    tipo={layerType}
                    setContent={setLayerContentList[layerType]}
                    city={cityName}
                    setHasIssues={setHasIssues}
                    existingJson=""
                />
            </div>
        ));
    };

    return (
        <>
            {alertMessage && <Alert message={alertMessage} type={alertMessageType} />}
            <div className="container add-city-container">
                <form onSubmit={formSubmitHandler}>
                    <div className="form-row add-city-form-header">
                        <h2 className="form-header-text">Nova Cidade</h2>
                        <div className="buttons-div">
                            <button className="btn-addCity-form btn-danger" onClick={cancelSubmitHandler}>Cancelar</button>
                            <button
                                className="btn-addCity-form btn-success"
                                type="submit"
                                disabled={hasIssues}
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>
                    <div className="add-city-form-row">
                        <div className="input-group mb-3 add-city-cityName">
                            <span className="input-group-text">
                                <BsMapFill size={25} />
                            </span>
                            <input
                                type="text"
                                name="city"
                                placeholder="   Nome da cidade"
                                value={cityName}
                                onChange={e => setCityName(e.target.value)}
                                required
                                className="form-control"
                                aria-label="City Name"
                            />
                        </div>
                        <div className="input-group mb-3 add-city-cityUF">
                            <span className="input-group-text add-city-cityUF-label">
                                UF
                            </span>
                            <input
                                type="text"
                                name="uf"
                                placeholder=" sigla"
                                value={siglaUF}
                                onChange={e => setSiglaUF(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Sigla UF"
                            />
                        </div>
                        <div className="input-group mb-3 add-city-cityCoord">
                            <span className="input-group-text add-city-cityCoord-label">
                                <BsFillGeoAltFill size={25} />
                            </span>
                            <input
                                type="text"
                                name="coord"
                                placeholder="ex:-43.05,-23.53"
                                value={coord}
                                onChange={e => setCoord(e.target.value)}
                                required
                                className="form-control"
                                aria-label="Coordenadas"
                            />
                        </div>
                        <div className="input-group mb-3 add-city-picoDU">
                            <span className="input-group-text add-city-picoDU-label">
                                DU
                            </span>
                            <input
                                type="text"
                                name="picoDU"
                                placeholder="ex: 07:00,09:00"
                                value={picoDU}
                                onChange={e => setPicoDU(e.target.value)}
                                className="form-control"
                                aria-label="Pico DU"
                            />
                        </div>
                        <div className="input-group mb-3 add-city-picoFDS">
                            <span className="input-group-text add-city-picoFDS-label">
                                FDS
                            </span>
                            <input
                                type="text"
                                name="picoFDS"
                                placeholder="ex: 07:00,09:00"
                                value={picoFDS}
                                onChange={e => setPicoFDS(e.target.value)}
                                className="form-control"
                                aria-label="Pico FDS"
                            />
                        </div>
                    </div>
                    <div className="add-city-form-uploaders-grid row">
                        {uploadersGrid()}
                    </div>
                </form>
            </div>
        </>
    )
}

export default NewUserPage;